import React, {useState} from 'react';
import {Utils} from "../../functions/Utils";
import {TypeRequest} from "../../functions/TypeRequest";
import {getToken} from "../../functions/CookieUtils";

function PanelBDDPerm({bddData, setNot}) {

    const [perm, setPerm] = useState({
        email: "",
        can_delete_bdd: false,
        can_add_perm: false,
        can_edit_password: false,
        can_edit_name: false,
        can_create_bdd: false,
        can_delete_perm: false,
        can_view_password: false
    });

    const [isInAdd, setIsInAdd] = useState(false);

    const [users, setUsers] = useState(null);

    function changeValue(name, value) {
        perm[name] = value;
        setPerm(perm);
    }

    async function addPerm(data = null, dataEdit = null) {
        if (!isInAdd) {
            setIsInAdd(true);

            if (data == null && perm.can_add_perm === false && perm.can_create_bdd === false && perm.can_delete_bdd === false && perm.can_delete_perm === false && perm.can_edit_name === false && perm.can_edit_password === false && perm.can_view_password === false) {
                setNot(Utils.createNot("no", "Erreur", "Vous devez définir au moins une permission."));
            } else {

                if (dataEdit != null) {
                    data[dataEdit] = !data[dataEdit];
                }

                const response = await Utils.requestApi(TypeRequest.OwnHeberg, "createBddPerm", data != null ? {
                    "token": getToken(),
                    "uuid": bddData.uuid,
                    "email": data.email,
                    "can_delete_bdd": data.can_delete_bdd ? 1 : 0,
                    "can_add_perm": data.can_add_perm ? 1 : 0,
                    "can_edit_password": data.can_edit_password ? 1 : 0,
                    "can_edit_name": data.can_edit_name ? 1 : 0,
                    "can_create_bdd": data.can_create_bdd ? 1 : 0,
                    "can_delete_perm": data.can_delete_perm ? 1 : 0,
                    "can_view_password": data.can_view_password ? 1 : 0
                }
                :
                {
                    "token": getToken(),
                    "uuid": bddData.uuid,
                    "email": perm.email,
                    "can_delete_bdd": perm.can_delete_bdd ? 1 : 0,
                    "can_add_perm": perm.can_add_perm ? 1 : 0,
                    "can_edit_password": perm.can_edit_password ? 1 : 0,
                    "can_edit_name": perm.can_edit_name ? 1 : 0,
                    "can_create_bdd": perm.can_create_bdd ? 1 : 0,
                    "can_delete_perm": perm.can_delete_perm ? 1 : 0,
                    "can_view_password": perm.can_view_password ? 1 : 0
                });

                if (response.out.valid === 1) {
                    setNot(Utils.createNot("yes", "Information", "La permission a bien été ajoutée."));
                    setUsers(null);
                } else if (response.out.valid === 3) {
                    setNot(Utils.createNot("no", "Erreur", "Valeur invalide."));
                } else if (response.out.valid === 4) {
                    setNot(Utils.createNot("no", "Erreur", "Vous n'avez pas la permission d'ajouter une permission."));
                } else if (response.out.valid === 5) {
                    setNot(Utils.createNot("yes", "Information", "Permissions modifiées."));
                    setUsers(null);
                } else if (response.out.valid === 6) {
                    setNot(Utils.createNot("no", "Erreur", "Vous avez déjà toutes les permissions."));
                } else {
                    setNot(Utils.createNot("no", "Erreur", "Une erreur est survenue lors de l'ajout de la permission."));
                }
            }

            await Utils.wait(2500);

            setNot(Utils.removeNot());
            setIsInAdd(false);
        }
    }

    async function getUsers() {
        const response = await Utils.requestApi(TypeRequest.OwnHeberg, "getBddPerm", {
            "token": getToken(),
            "uuid": bddData.uuid
        });

        if (response.out.valid === 1) {
            setUsers(response.out);
        }
    }

    async function deletePerm(email) {
        const response = await Utils.requestApi(TypeRequest.OwnHeberg, "deleteBddPerm", {
            "token": getToken(),
            "uuid": bddData.uuid,
            "email": email
        });

        if (response.out.valid === 1) {
            setNot(Utils.createNot("yes", "Information", "La permission a bien été supprimée."));
            setUsers(null);
        } else if (response.out.valid === 3) {
            setNot(Utils.createNot("no", "Erreur", "Valeur invalide."));
        } else if (response.out.valid === 4) {
            setNot(Utils.createNot("no", "Erreur", "Vous n'avez pas la permission de supprimer une permission."));
        } else {
            setNot(Utils.createNot("no", "Erreur", "Une erreur est survenue lors de la suppression de la permission."));
        }

        await Utils.wait(2500);

        setNot(Utils.removeNot());
    }

    function getUsersFront() {

        if (users === null) {
            return (
                <div className="panel-instance">
                    <div className="instance-loading">
                        <span>Chargement de vos factures<i className="fa-solid fa-sync fa-spin"></i></span>
                    </div>
                </div>
            );
        }
        else if (users["usersCount"] === 0) {
            return <p className="text-center-align">Aucun utilisateur n'est enregistré.</p>;
        } else {
            let usersList = [];

            const usersCount = users["usersCount"];

            for (let i = 1; i <= usersCount; i++) {
                const email = users["users"][i]["user_email"];

                const temp = {
                    id: i,
                    email: email,
                    can_delete_bdd: users["users"][i]["can_delete_bdd"] === "1",
                    can_add_perm: users["users"][i]["can_add_perm"] === "1",
                    can_edit_password: users["users"][i]["can_edit_password"] === "1",
                    can_edit_name: users["users"][i]["can_edit_name"] === "1",
                    can_create_bdd: users["users"][i]["can_create_bdd"] === "1",
                    can_delete_perm: users["users"][i]["can_delete_perm"] === "1",
                    can_view_password: users["users"][i]["can_view_password"] === "1"
                };

                usersList.push(temp);
            }

            return (
                <ul className="users-list">
                    {usersList.map(user => {
                        return (
                            <li className="users-element">
                                <div className="box text-center-align">
                                    <div className="case-auto">
                                        {user.email}
                                    </div>
                                    <div className="case-auto">
                                        <a className="btn-sur btn-oh-black" href={"#user-" + user.id}>Voir les permissions</a>
                                        <a className="btn-sur btn-oh-red" onClick={e => deletePerm(user.email)}>Supprimer</a>
                                    </div>
                                </div>

                                <div id={"user-" + user.id} className="overlay">
                                    <div className="popup">
                                        <a className="close" href="#"><i className="fa-solid fa-xmark"></i></a>

                                        <h3>Permissions</h3>
                                        <h5>{user.email}</h5>

                                        <div className="content">
                                            <a onClick={e => addPerm(user, "can_edit_name")}>{user.can_edit_name ? <i className="fa-solid fa-circle-check yes-perm"></i> : <i className="fa-solid fa-circle-xmark no-perm"></i>} Changer le nom du service.</a>
                                            <a onClick={e => addPerm(user, "can_create_bdd")}>{user.can_create_bdd ? <i className="fa-solid fa-circle-check yes-perm"></i> : <i className="fa-solid fa-circle-xmark no-perm"></i>} Créer la base de données.</a>
                                            <a onClick={e => addPerm(user, "can_delete_bdd")}>{user.can_delete_bdd ? <i className="fa-solid fa-circle-check yes-perm"></i> : <i className="fa-solid fa-circle-xmark no-perm"></i>} Supprimer la base de données.</a>
                                            <a onClick={e => addPerm(user, "can_view_password")}>{user.can_view_password ? <i className="fa-solid fa-circle-check yes-perm"></i> : <i className="fa-solid fa-circle-xmark no-perm"></i>} Voir le mot de passe.</a>
                                            <a onClick={e => addPerm(user, "can_edit_password")}>{user.can_edit_password ? <i className="fa-solid fa-circle-check yes-perm"></i> : <i className="fa-solid fa-circle-xmark no-perm"></i>} Changer le mot de passe.</a>
                                            <a onClick={e => addPerm(user, "can_add_perm")}>{user.can_add_perm ? <i className="fa-solid fa-circle-check yes-perm"></i> : <i className="fa-solid fa-circle-xmark no-perm"></i>} Ajouter des permissions.</a>
                                            <a onClick={e => addPerm(user, "can_delete_perm")}>{user.can_delete_perm ? <i className="fa-solid fa-circle-check yes-perm"></i> : <i className="fa-solid fa-circle-xmark no-perm"></i>} Supprimer des permissions.</a>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        );
                    })}
                </ul>
            );
        }
    }

    if (users === null) getUsers().then();

    return (
        <div className="bdd-panel">
            <div className="box box-lm-col">
                <div className="case-auto bdd-case">
                    <div className="brand">
                        <i className="fa-solid fa-user"></i>Ajouter des permissions à un utilisateur
                    </div>

                    <hr/>

                    <div className="bdd-perm">
                        <div className="perm-add-case">
                            <h4>Entrez l'adresse email de l'utilisateur :</h4>
                            <input type="email" className="input input-oh-black input-block" placeholder="Adresse email de l'utilisateur" name="email" onChange={e => changeValue(e.target.name, e.target.value)} disabled={isInAdd}/>
                        </div>

                        <div className="perm-add-case">
                            <h4>Sélectionner les permissions :</h4>

                            <div className="perm-check">
                                <input type="checkbox" className="input input-oh-black" name="can_edit_name" onChange={e => changeValue(e.target.name, e.target.checked)} disabled={isInAdd}/>
                                <span>Changer le nom du service.</span>
                            </div>

                            <div className="perm-check">
                                <input type="checkbox" className="input input-oh-black" name="can_create_bdd" onChange={e => changeValue(e.target.name, e.target.checked)} disabled={isInAdd}/>
                                <span>Créer la base de données.</span>
                            </div>

                            <div className="perm-check">
                                <input type="checkbox" className="input input-oh-black" name="can_delete_bdd" onChange={e => changeValue(e.target.name, e.target.checked)} disabled={isInAdd}/>
                                <span>Supprimer la base de données.</span>
                            </div>

                            <div className="perm-check">
                                <input type="checkbox" className="input input-oh-black" name="can_view_password" onChange={e => changeValue(e.target.name, e.target.checked)} disabled={isInAdd}/>
                                <span>Voir le mot de passe.</span>
                            </div>

                            <div className="perm-check">
                                <input type="checkbox" className="input input-oh-black" name="can_edit_password" onChange={e => changeValue(e.target.name, e.target.checked)} disabled={isInAdd}/>
                                <span>Changer le mot de passe.</span>
                            </div>

                            <div className="perm-check">
                                <input type="checkbox" className="input input-oh-black" name="can_add_perm" onChange={e => changeValue(e.target.name, e.target.checked)} disabled={isInAdd}/>
                                <span>Ajouter des permissions.</span>
                            </div>

                            <div className="perm-check">
                                <input type="checkbox" className="input input-oh-black" name="can_delete_perm" onChange={e => changeValue(e.target.name, e.target.checked)} disabled={isInAdd}/>
                                <span>Supprimer des permissions.</span>
                            </div>
                        </div>

                        {isInAdd ?
                            <a className="btn btn-block btn-oh-black" href><i className="fa-solid fa-sync fa-spin"></i></a>
                            :
                            <a className="btn btn-block btn-oh-black" onClick={addPerm}>Enregistrer</a>
                        }
                    </div>
                </div>

                <div className="case-auto bdd-case">
                    <div className="brand">
                        <i className="fa-solid fa-list"></i>Liste des utilisateurs autorisés
                    </div>

                    <hr/>

                    <div className="bdd-perm">
                        {getUsersFront()}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PanelBDDPerm;