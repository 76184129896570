import React, {useReducer, useState} from 'react';
import CardProduct from "../Cards/CardProduct";
import {NavLink, useNavigate, useSearchParams} from "react-router-dom";
import {Utils} from "../../functions/Utils";
import {TypeRequest} from "../../functions/TypeRequest";
import PaypalButton from "../PaypalButton";
import {getToken} from "../../functions/CookieUtils";

function PanelBuy({ offerUuid, userData, setUserData, setNot }) {

    const navigate = useNavigate();

    const [searchParams] = useSearchParams();

    const [inPaid, setInPaid] = useState(false);

    const [offer, setOffer] = useState(null);
    const [month, setMonth] = useState(1);
    const [ignored, forceUpdate] = useReducer(x => x + 1, 0);

    const instanceType = searchParams.get("type");
    let instanceExist = true;

    function returnUrl() {
        window.history.back();
    }
    async function getOfferInfo() {
        const response = await Utils.requestApi(TypeRequest.OwnHeberg,"getOfferInfo", { offerToken: offerUuid });
        setOffer(response["out"]);
    }

    if (offer === null) {
        getOfferInfo().then();
    }

    // Verify if instance type exist for current offer
    if (offerUuid === "T6HTI7" || offerUuid === "3GM5RB" || offerUuid === "982IYI") {
        if (instanceType !== "Java" && instanceType !== "NodeJS" && instanceType !== "Python") {
            instanceExist = false;
        }
    }

    if (offer === null || userData === null) {
        return (
            <div className="panel-instance">
                <div className="instance-loading">
                    <span>Chargement de l'offre<i className="fa-solid fa-sync fa-spin"></i></span>
                </div>
            </div>
        );
    } else {

        if (!offer["inStock"]) {
            return (
                <div className="panel-instance">
                    <div className="instance-loading">
                        <span>Cette offre n'est plus en stock.</span>
                    </div>
                </div>
            );
        } else if (offer["exist"] && instanceExist) {

            const dataPayment = {
                instanceType: instanceType,
                instanceUUID: offerUuid,
                price: (month * offer["price"]).toFixed(2),
                month: month
            };

            const pricePerMonth = offer["price"];

            function setSelectedMonth(e, data) {
                setMonth(data);
                dataPayment.month = month;
                forceUpdate();
            }

            async function paidWithCredit() {
                if (!inPaid) {
                    setInPaid(true);

                    const response = await Utils.requestApi(TypeRequest.OwnHeberg, "paidWithPb",
                        {
                            "token": getToken(),
                            "typeBuy": "new",
                            "offerToken": instanceType == null ? offerUuid : offerUuid + ":" + instanceType,
                            "month": month
                        });

                    if (response.out.valid === 1) {

                        if (response.out.result === 2) {
                            setNot(Utils.createNot("no", "Erreur", "Il n'y a plus de stock pour cette offre, vous n'avez pas été débité. Redirection vers le panel des services ..."));
                        }
                        else if (response.out.result === 1) {
                            setNot(Utils.createNot("yes", "Information", "Achat effectué, votre serveur est en cours de création ! Redirection vers le panel des services ..."));

                            userData.pb = parseInt(userData.pb) - parseInt((dataPayment.price * 100).toFixed());
                            setUserData(userData);
                            forceUpdate()
                        }

                        await Utils.wait(4000);

                        navigate("/panel/instance", { replace: true });
                        window.location.reload();
                    } else {
                        setNot(Utils.createNot("no", "Erreur", "Erreur lors de l'achat, essayez utlérieurement ou utilisez un autre moyen de paiement."));
                    }

                    await Utils.wait(2500);

                    setNot(Utils.removeNot());
                    setInPaid(false);
                }
            }

            return (
                <div className="panel-instance">
                    <h3>Personnaliser votre offre</h3>
                    <div className="box box-lm-col">
                        <div className="case-auto credit-case">
                            <div className="credit-title">
                                <i className="fa-solid fa-server"></i>Offre sélectionnée
                            </div>

                            <hr />

                            <div className="box box-col">
                                <CardProduct idOffer={offerUuid} isBuy={true} instanceType={instanceType} />
                            </div>
                        </div>

                        <div className="case-auto credit-case">
                            <div className="credit-title">
                                <i className="fa-regular fa-square-check"></i>Sélectionnez vos options
                            </div>

                            <hr />

                            <div className="box box-lm-col text-center-align month-margin">
                                Il n'y a pas d'options supplémentaires disponibles pour cette offre.
                            </div>
                        </div>
                    </div>

                    <div className="box box-lm-col">
                        <div className="case-auto credit-case">
                            <div className="credit-title">
                                <i className="fa-solid fa-calendar-days"></i>Durée de l'offre
                            </div>

                            <hr />

                            <div className="box box-lm-col text-center-align month-margin">

                                {Array.from(Array(6)).map((e, i) => (
                                    <div className="case-auto select-month">
                                        <a onClick={((e) => setSelectedMonth(e, i+1))} className="btn-xs btn-oh-dark-two">{i+1} mois</a>
                                    </div>
                                ))}

                            </div>

                        </div>
                    </div>

                    <div className="box box-lm-col">
                        <div className="case-auto credit-case">
                            <div className="credit-title">
                                <i className="fa-solid fa-list-check"></i>Récapitulatif
                            </div>

                            <hr />

                            <div className="credit-recap">
                                <p className="text-center-align">Prix mensuel : <strong>{pricePerMonth} €</strong> TTC.</p>
                                <p className="text-center-align">Vous avez choisi cette offre pour une durée de <strong>{month} mois</strong>, renouvellement possible par la suite.</p>
                                <p className="text-center-align total">Prix un total de <strong>{dataPayment.price} €</strong> TTC.</p>
                            </div>
                        </div>
                    </div>

                    <a className="btn btn-block btn-oh-red return-btn" href="#" onClick={returnUrl}><i className="fa-solid fa-rotate-left"></i>Retour au choix de l'offre</a>

                    <a className="btn btn-block btn-oh-green pay-btn" href="#payment"><i className="fa-solid fa-cash-register"></i>Procéder au paiement</a>
                    <div id="payment" className="overlay">
                        <div className="popup">
                            <a className="close" href="#"><i className="fa-solid fa-xmark"></i></a>

                            <h3>Mode de paiement</h3>
                            <h5>Vous vous apprêtez à effectuer un paiement de <strong>{dataPayment.price} €</strong> TTC.</h5>

                            <div className="content">

                                <h4><i className="fa-brands fa-paypal"></i>PayPal</h4>
                                {userData &&
                                    <PaypalButton type="new" dataPayment={dataPayment} userData={userData} setUserData={setUserData} forceUpdate={forceUpdate}></PaypalButton>
                                }

                                <h4><i className="fa-solid fa-cubes"></i>Paiement par crédit</h4>
                                {userData.pb >= parseInt((dataPayment.price * 100).toFixed()) ?
                                    (
                                        <>
                                            {inPaid ?
                                                <a className="btn btn-block btn-oh-dark-clicked"><i className="fa-solid fa-sync fa-spin"></i></a>
                                                :
                                                <a className="btn btn-block btn-oh-black" onClick={paidWithCredit}>Acheter pour {parseInt((dataPayment.price * 100).toFixed())} crédits</a>
                                            }
                                        </>
                                    )
                                    :
                                    (
                                        <div className="text-center-align mini-title">
                                            <p>Vous n'avez pas suffisamment de crédits, il vous manque <strong>{(dataPayment.price * 100).toFixed() - userData.pb}</strong> crédit(s).</p>
                                            <NavLink exact to="/panel/credit" className="btn-xs btn-oh-black">Créditer mon compte</NavLink>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>

            );
        } else {
            return (
                <div className="panel-instance">
                    <div className="instance-loading">
                        <span>Ce service n'existe pas.</span>
                    </div>
                </div>
            );
        }
    }
}

export default PanelBuy;