import React, {useState} from 'react';
import {Utils} from "../../functions/Utils";
import {TypeRequest} from "../../functions/TypeRequest";
import {getToken} from "../../functions/CookieUtils";
import {NavLink, useNavigate} from "react-router-dom";

function PanelBDDSettings({ userData, bddData, setBddData, setNot }) {
    if (bddData.bdd_name === null) {
        return (
            <div className="home-panel-container">
                <h3 className="title">Cette base de données n'est pas encore configurée.</h3>

                <NavLink exact to={"/panel/bdd/" + bddData.uuid} className="btn btn-oh-black btn-block">Configurer</NavLink>
            </div>
        );
    } else {
        return <PanelFront bddData={bddData} setBddData={setBddData} setNot={setNot} />;
    }
}

function PanelFront({bddData, setBddData, setNot}) {

    const navigate = useNavigate();

    const [confirm, setConfirm] = useState(false);
    const [isInDelete, setIsInDelete] = useState(false);
    const [isInUpdate, setIsInUpdate] = useState(false);

    const [serviceName, setServiceName] = useState(bddData.bdd_name);

    async function deleteBdd() {
        if (!isInDelete) {
            setIsInDelete(true);

            if (confirm) {
                const response = await Utils.requestApi(TypeRequest.OwnHeberg, "deleteBdd", { "token": getToken(), "uuid": bddData.uuid });
                if (response.out.valid === 1) {
                    setNot(Utils.createNot("yes", "Information", "Votre base de données a bien été supprimée. Redirection en cours..."));

                    await Utils.wait(3000);

                    navigate("/panel/instance", { replace: true });
                    window.location.reload();
                } else if (response.out.valid === 4) {
                    setNot(Utils.createNot("no", "Erreur", "Vous n'avez pas la permission de supprimer cette base de données."));
                } else if (response.out.valid === 5) {
                    setNot(Utils.createNot("no", "Erreur", "Cette base de données n'existe pas."));
                } else {
                    setNot(Utils.createNot("no", "Erreur", "Une erreur est survenue lors de la suppression de votre base de données."));
                }
            } else {
                setNot(Utils.createNot("no", "Erreur", "Vous devez confirmer la suppression de votre base de données."));
            }
        } else {
            setNot(Utils.createNot("no", "Erreur", "Vous devez confirmer la suppression de la base de données."));
        }

        await Utils.wait(2500);

        setNot(Utils.removeNot());
        setIsInDelete(false);
    }

    async function updateBdd() {
        if (!isInUpdate) {
            setIsInUpdate(true);

            const response = await Utils.requestApi(TypeRequest.OwnHeberg, "updateBdd", { "token": getToken(), "uuid": bddData.uuid, "service_name": serviceName });
            if (response.out.valid === 1) {
                setNot(Utils.createNot("yes", "Information", "Le nom de votre service a bien été modifié."));

                bddData.name = serviceName;
                setBddData(bddData);
            } else if (response.out.valid === 4) {
                setNot(Utils.createNot("no", "Erreur", "Vous n'avez pas la permission de modifier le nom de ce service."));
            }  else if (response.out.valid === 3) {
                setNot(Utils.createNot("no", "Erreur", "Les caractères spéciaux ne sont pas autorisés."));
            } else {
                setNot(Utils.createNot("no", "Erreur", "Une erreur est survenue lors de la modification du nom de votre service."));
            }
        }

        await Utils.wait(2500);

        setNot(Utils.removeNot());
        setIsInUpdate(false);
    }

    return (
        <div className="bdd-panel">
            <div className="box box-lm-col">
                <div className="case-auto bdd-case">
                    <div className="brand">
                        <i className="fa-solid fa-font"></i>Modifier le nom du service
                    </div>

                    <hr/>

                    <div className="bdd-settings">
                        <h4>Nom du service</h4>
                        <input type="text" className="input input-oh-black input-block" placeholder={bddData.name} onChange={e => setServiceName(e.target.value)} disabled={isInUpdate}/>
                        <a className="btn btn-block btn-oh-black" onClick={updateBdd}>{isInUpdate ? <i className="fa-solid fa-sync fa-spin"></i> : "Mettre à jour"}</a>
                    </div>
                </div>

                <div className="case-auto bdd-case">
                    <div className="brand">
                        <i className="fa-solid fa-trash-can"></i>Supprimer la base de données
                    </div>

                    <hr/>

                    <div className="bdd-settings">
                        <h4>Suppression totale</h4>
                        <p>Attention, la suppression concerne toutes les tables et toutes vos données stockées dans la base de données.</p>
                        <a className="btn btn-block btn-oh-red" href="#delete">Supprimer la base de données</a>

                        <div id="delete" className="overlay">
                            <div className="popup">
                                <a className="close" href="#"><i className="fa-solid fa-xmark"></i></a>

                                <h3>Suppression totale</h3>
                                <h5>{bddData.bdd_name}</h5>

                                <div className="content">
                                    <p>Une fois la suppression effectuée, vous serez invité à recréer la base de données.</p>
                                    <p>Seules les permissions serons conservées.</p>
                                    <p className="consent"><input type="checkbox" className="input input-oh-black" onChange={e => setConfirm(e.target.checked)} disabled={isInDelete} />Je consens que mes données soient supprimées.</p>
                                    <a className="btn btn-block btn-oh-red" onClick={deleteBdd}>{isInDelete ? <i className="fa-solid fa-sync fa-spin"></i> : "Supprimer la base de données"}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PanelBDDSettings;