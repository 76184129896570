import React from 'react';
import {NavLink} from "react-router-dom";
import packageJson from '../../../package.json';

const Header = () => {
    return (
        <div className="footer bg-oh-blue-mat">
            <div>Copyright © <strong className="text-background">2022-{new Date().getFullYear()} </strong>| Version du site <strong className="text-background">v{packageJson.version}</strong> | OwnHeberg édité par <strong className="text-background">OwnTech</strong></div>
            <NavLink className="text-background" exact to="/cg">CGU CGV</NavLink> - <a className="text-background" href="https://owntech.fr/legal" target="_blank" rel="noreferrer">Mentions légales</a>
        </div>
    );
};

export default Header;