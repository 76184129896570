import React, {useEffect, useState} from "react";
import {NavLink, useNavigate, useSearchParams} from "react-router-dom";
import {Utils} from "../functions/Utils";
import {setToken} from "../functions/CookieUtils";
import {TypeRequest} from "../functions/TypeRequest";
import Account from "../components/Account";

function Login() {

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const confirmEmail = searchParams.get("confirm");

    const [isInLogin, setIsInLogin] = useState(false);

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [remember, setRemember] = useState(false);

    const [not, setNot] = useState({
        class: "",
        title: "",
        data: ""
    });

    useEffect(() => {
        async function verifyToken(token) {
            await Utils.requestApi(TypeRequest.OwnTech,"confirmEmail", { "emailToken": token });
        }

        if (confirmEmail !== null) verifyToken(confirmEmail).then();
    }, [confirmEmail]);

    async function loginRequest() {
        if (!isInLogin) {
            setIsInLogin(true);

            const errorText = "Erreur de connexion";

            if (email === "" || password === "") {
                setNot(Utils.createNot("no", errorText, "Vous devez remplir les champs."));
            }
            else {
                const response = await Utils.requestApi(TypeRequest.OwnTech,"loginRequest", {
                        "email": email,
                        "password": await Utils.sha256(password)
                });

                if (response["out"]["valid"] === 1) {
                    await setToken(response["out"]["token"], remember);

                    setNot(Utils.createNot("yes", "Information", "Connexion effectuée ! Redirection vers le panel utilisateur ..."));

                    await Utils.wait(1500);

                    if (window.location.pathname === "/login") {
                        navigate("/panel", { replace: true });
                    }
                    window.location.reload();
                }
                else if (response["out"]["valid"] === 2) {
                    setNot(Utils.createNot("no", errorText, "Mot de passe ou adresse email incorrecte."));
                }
                else if (response["out"]["valid"] === 3) {
                    setNot(Utils.createNot("no", errorText, "Ceci n'est pas une adresse email valide."));
                }
                else if (response["out"]["valid"] === 4) {
                    setNot(Utils.createNot("no", errorText, "Ceci n'est pas un mot de passe valide."));
                }
                else if (response["out"]["valid"] === 5) {
                    setNot(Utils.createNot("no", "Confirmation adresse e-mail", "Merci de valider votre adresse e-mail depuis le mail qui vient d'être envoyé."));

                    await Utils.wait(1500);
                }
                else if (response["out"]["access"] === "denied") {
                    setNot(Utils.createNot("no", "Nombre de tentatives dépassé", "Vous avez dépassé le nombre de tentatives de connexion autorisées. Veuillez réessayer dans 30 minutes."));

                    await Utils.wait(1500);
                }
                else {
                    setNot(Utils.createNot("no", errorText, "Une erreur interne est survenue."));
                }
            }

            await Utils.wait(2500);

            setNot(Utils.removeNot());
            setIsInLogin(false);
        }
    }

    return (
        <div>
            <div className={not.class}>
                <span className="not-title">{not.title}</span>
                <span className="not-data">{not.data}</span>
            </div>

            <div className="box box-lm-col box-all">

                <Account />

                <div className="case-auto bg-oh-dark">
                    <div className="login-container">
                        <div className="title">
                            <h2>Connexion à votre espace client</h2>
                            <p>Connectez-vous à votre compte <span className="text-background">OwnTech</span> pour gérer vos services.</p>
                            <p>Si vous avez créé votre compte chez un autre service d'<span className="text-background">OwnTech</span>, la première connexion vous fera accepter les <NavLink exact to="/cg" className="text-background">conditions générales</NavLink> d'OwnHeberg.</p>
                        </div>

                        <div className="input-container">
                            <h4>Adresse email <strong>*</strong></h4>
                            <input name="email" type="text" className="input input-oh-black" placeholder="Adresse email" onChange={e => setEmail(e.target.value)} disabled={isInLogin} />

                            <h4>Mot de passe <strong>*</strong></h4>
                            <input name="password" type="password" className="input input-oh-black" placeholder="Mot de passe" onChange={e => setPassword( e.target.value)} disabled={isInLogin} />
                            <NavLink className="forgot-password" exact to="/forgot">Mot de passe oublié ?</NavLink>
                        </div>

                        <div className="end">
                            <div className="remember">
                                <input name="remember" type="checkbox" onChange={e => setRemember(e.target.checked)} disabled={isInLogin} />
                                <span className="hovertext" data-hover="Sauvegarde votre session pendant 4 jours.">Se souvenir de moi</span>
                            </div>

                            <a name="login" onClick={loginRequest} className="btn btn-oh-black" href>{isInLogin ? <i className="fa-solid fa-sync fa-spin"></i> : "Connexion"}</a>
                        </div>

                        <div className="no-account">
                            <h4>Vous n'avez pas de compte <span className="text-background">OwnTech</span> ?</h4>
                            <NavLink exact to="/register" className="btn-xs btn-oh-black">Créer un compte</NavLink>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;