import React, {useState} from 'react';
import {Utils} from "../../functions/Utils";
import {TypeRequest} from "../../functions/TypeRequest";
import {getToken} from "../../functions/CookieUtils";

function PanelBDDHome({ userData, bddData, setBddData, setNot }) {

    const [bddName, setBddName] = useState(null);

    const [isInCreate, setIsInCreate] = useState(false);

    if (bddData.bdd_name === null) {

        async function createBdd() {
            if (!isInCreate) {
                setIsInCreate(true);

                const response = await Utils.requestApi(TypeRequest.OwnHeberg, "createBdd", { "token": getToken(), "uuid": bddData.uuid, "name": bddName });

                if (response.out.valid === 1) {
                    setNot(Utils.createNot("yes", "Information", "Base de données créée avec succès !"));

                    bddData.bdd_name = bddData.bdd_owner + "_" + bddName;
                    setBddData(bddData);
                } else if (response.out.valid === 3) {
                    setNot(Utils.createNot("no", "Erreur", "La taille du nom doit-être compris entre 3 et 18 caractères."));
                } else if (response.out.valid === 4) {
                    setNot(Utils.createNot("no", "Erreur", "Vous n'avez pas la permission de créer la base de données."));
                } else if (response.out.valid === 5) {
                    setNot(Utils.createNot("no", "Erreur", "Ce nom de base de données existe déjà."));
                } else if (response.out.valid === 6) {
                    setNot(Utils.createNot("no", "Erreur", "Vous ne pouvez pas créer cette base de données car le service est suspendu."));
                } else {
                    setNot(Utils.createNot("no", "Erreur", "Une erreur est survenue lors de la création de la base de données."));
                }

                await Utils.wait(3000);

                setNot(Utils.removeNot());
                setIsInCreate(false);
            }
        }

        return (
            <div className="bdd-panel">
                <h3 className="title">Cette base de données n'est pas encore configurée.</h3>

                <div className="box box-lm-col">
                    <div className="case-auto bdd-case">
                        <div className="brand">
                            <i className="fa-solid fa-font"></i>Définir le nom de la base de données
                        </div>

                        <hr/>

                        <div className="bdd-create">
                            <div className="input-pre">
                                <span className="input input-oh-black">{bddData.bdd_owner}_</span>
                                <input type="text" className="input input-oh-black" pattern="[a-z]{3,18}" data-hover="Votre service est en cours d'installation." onChange={e => setBddName(e.target.value)} disabled={isInCreate}/>
                            </div>

                            {isInCreate ?
                                <a className="btn btn-oh-black btn-block" href><i className="fa-solid fa-sync fa-spin"></i></a>
                                :
                                <a className="btn btn-oh-black btn-block" onClick={createBdd}>Créer la base de données</a>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    } else {
        return <PanelFront userData={userData} bddData={bddData}/>;
    }
}

function PanelFront({ userData, bddData }) {

    return (
        <div className="bdd-panel">
            <div className="box box-lm-col">
                <div className="case-auto bdd-case">
                    <div className="brand">
                        <i className="fa-solid fa-font"></i>Informations de la base de données
                    </div>

                    <hr/>

                    <div className="bdd-home">
                        <div className="info-card">
                            <h4>Nom de la base de données</h4>
                            <input className="input input-block input-oh-black" value={bddData.bdd_name} disabled />
                        </div>

                        <div className="info-card">
                            <h4>Nom de l'offre</h4>
                            <input className="input input-block input-oh-black" value={bddData.bdd_name} disabled />
                        </div>

                        <h4>Propriétaire de la base de données</h4>
                        <input className="input input-block input-oh-black" value={bddData.bdd_owner} disabled />
                    </div>
                </div>

                <div className="case-auto bdd-case">
                    <div className="brand">
                        <i className="fa-solid fa-info"></i>Limitations de la base de données
                    </div>

                    <hr/>

                    <div className="bdd-home">
                        <div className="info-card">
                            <h4>Connexions simultanées autorisées</h4>
                            <input className="input input-block input-oh-black" value={bddData.max_connection === "0" ? "Illimité" : bddData.max_connection} disabled />
                        </div>

                        <h4>Stockage</h4>
                        <p>{bddData.bdd_size} / {bddData.max_storage}mo</p>
                        <progress id="file" max={bddData.max_storage} value={bddData.bdd_size}></progress>
                        <h6>Attention, en cas de dépassement du quota de stockage, votre base de données sera bloquée.</h6>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PanelBDDHome;