import React, {useState} from 'react';
import {Utils} from "../../functions/Utils";
import {TypeRequest} from "../../functions/TypeRequest";
import {getToken} from "../../functions/CookieUtils";

const urlServer = process.env.REACT_APP_API_URL_PAYMENT;

function PanelInvoice() {

    const [factures, setFactures] = useState(null);

    async function getFactures() {
        const response = await Utils.requestApi(TypeRequest.OwnHeberg, "getFactures", { "token":  getToken()});
        setFactures(response.out);
    }

    if (factures === null)
        getFactures().then();

    function getFactureFront() {

        const apiFacture = `${urlServer}api/getfacture/OH/`;
        const options = { weekday: "long", year: "numeric", month: "long", day: "numeric", hour: "numeric", minute: "numeric" };

        if (factures === null) {
            return (
                <div className="panel-instance">
                    <div className="instance-loading">
                        <span>Chargement de vos factures<i className="fa-solid fa-sync fa-spin"></i></span>
                    </div>
                </div>
            );
        }
        else if (factures["amount"] === 0) {
            return <p>Vous n'avez pas de facture.</p>;
        } else {
            let facturesList = [];

            const facturesCount = factures["amount"];

            for (let i = 1; i <= facturesCount; i++) {
                const orderId = factures[i]["order_id"];
                const amount = factures[i]["amount"];
                const transactionId = factures[i]["transaction_id"];
                const date = new Date(factures[i]["date"] * 1000);

                const temp = {
                    id: i,
                    orderId: orderId,
                    amount: amount,
                    transactionId: transactionId,
                    date: date.toLocaleDateString("fr-FR", options)
                };

                facturesList.push(temp);
            }

            return (
                <ul className="credit-history-list">
                    <li className="credit-history-title">
                        <div className="box text-center-align">
                            <div className="case-auto">Date de paiement</div>
                            <div className="case-auto">Prix</div>
                            <div className="case-auto">Numéro de transaction</div>
                            <div className="case-auto">Facture PDF</div>
                        </div>
                    </li>
                    {facturesList.map(facture => {
                        return (
                            <li className="credit-history-element">
                                <div className="box text-center-align">
                                    <div className="case-auto">{facture.date}</div>
                                    <div className="case-auto">{facture.amount + " €"}</div>
                                    <div className="case-auto">{facture.transactionId}</div>
                                    <div className="case-auto"><a className="btn-sur btn-oh-black" href={apiFacture + facture.orderId} target="_blank">Obtenir la fature</a></div>
                                </div>
                            </li>
                        );
                    })}
                </ul>
            );
        }
    }

    return (
        <>
            <div className="box box-lm-col">
                <div className="case-auto credit-case">
                    <div className="credit-title">
                        <i className="fa-solid fa-file-lines"></i>Vos factures
                    </div>

                    <hr />

                    {getFactureFront()}

                </div>
            </div>
        </>
    );
}

export default PanelInvoice;