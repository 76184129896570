import React, {useEffect} from 'react';

import Header from "../components/Helpers/Header";
import Footer from "../components/Helpers/Footer";
import Title from "../components/Title";
import CardInstance from "../components/Cards/CardInstance";
import CardInfo from "../components/Cards/CardInfo";
import {useParams} from "react-router-dom";
import {setCodeCookie} from "../functions/CookieUtils";

function Home({ userData }) {

    const params = useParams();

    const code = params.code ?? null;

    useEffect(() => {
        async function setCode() {
            setCodeCookie(code);
        }

        if (code !== null) setCode().then();
    }, [code]);

    return (
        <div>
            <Header userData={userData} />

            <div className="home bg-oh-dark-light">

                <Title
                    title="Centraliser votre activité"
                    desc="Chez OwnHeberg, nous hébergeons vos applications et vos bases de données sur des instances personnalisées, taillées sur mesure pour répondre à chacun de vos besoins."
                />

                <div className="card">

                    <h1 className="text-background home-title">Un large choix d'hébergement</h1>
                    <div className="home-card-container">
                        <div className="box box-wrap">
                            <CardInstance
                                title="Serveur Minecraft"
                                img="fa-solid fa-cube text-background"
                                miniDesc="Accéder aux offres"
                                miniIcon="fa-solid fa-circle-info text-background"
                                link="/cloud/minecraft"
                            />

                            <CardInstance
                                title="Hébergement Web"
                                img="fa-brands fa-php text-background fa-big"
                                miniDesc="Prochainement disponible"
                                miniIcon="fa-solid fa-person-digging text-background"
                                //link="/cloud/web"
                            />
                        </div>
                        <div className="box box-wrap">
                            <CardInstance
                                title="Instance NodeJS"
                                img="fa-brands fa-node text-background fa-big"
                                miniDesc="Accéder aux offres"
                                miniIcon="fa-solid fa-circle-info text-background"
                                link="/cloud/instance/nodejs"
                            />

                            <CardInstance
                                title="Instance Java"
                                img="fa-brands fa-java text-background fa-big"
                                miniDesc="Accéder aux offres"
                                miniIcon="fa-solid fa-circle-info text-background"
                                link="/cloud/instance/java"
                            />

                            <CardInstance
                                title="Instance Python"
                                img="fa-brands fa-python text-background fa-big"
                                miniDesc="Accéder aux offres"
                                miniIcon="fa-solid fa-circle-info text-background"
                                link="/cloud/instance/python"
                            />
                        </div>
                        <div className="box box-wrap">
                            <CardInstance
                                title="Votre instance personnalisée"
                                img="fa-solid fa-server text-background"
                                miniDesc="Contactez-nous"
                                miniIcon="fa-solid fa-file-export text-background"
                            >
                                La seule limite est votre imagination ! Vous avez besoin d'un VPS, de plus de puissance, ... ?
                                Discutons ensemble de vos besoins et nous répondrons avec des outils adaptés et notre expertise professionnelle.
                                Contactez-nous sur Discord ou par e-mail pour discuter de votre projet.
                            </CardInstance>
                        </div>
                    </div>

                    <h1 className="text-background home-title">Exigez de nous le meilleur</h1>
                    <div className="home-card-container">
                        <CardInfo
                            miniTitle="Environnement intuitif"
                            title="Panel de gestion personnalisé"
                            img="/img/info/info1.webp"
                            place="left"
                        >
                            Nous utilisons les célèbres panels Pterodactyl et Plesk, que nous avons modifiés pour les rendre uniques.
                            Le panel OwnHeberg vous permet une gestion complète de vos services professionnels ainsi qu'une vision des statistiques complète et précise.
                        </CardInfo>

                        <CardInfo
                            miniTitle="Performance et qualité"
                            title="Outils à la pointe de la technologie"
                            img="/img/info/info2.webp"
                            place="right"
                        >
                            Nos serveurs sont configurés de manière à répondre aux besoins spécifiques de chaque offre que nous proposons, afin de vous garantir un service de qualité.
                            Nous sélectionnons les composants les plus adaptés et optimisés pour chaque service pour répondre à vos exigences en matière de performance et de fiabilité.
                        </CardInfo>

                        <CardInfo
                            miniTitle="Localisation des serveurs"
                            title="Serveurs hébergés en France"
                            img="/img/info/info3.webp"
                            place="left"
                        >
                            Nos serveurs sont hébergés en France et offrent un ping très bas. Nous surveillons nos serveurs jour et nuit pour assurer une disponibilité constante.
                            Nous utilisons la technologie Raid pour protéger vos données grâce à la redondance des données.
                        </CardInfo>
                    </div>

                    <h1 className="text-background home-title">Nos réseaux</h1>
                    <div className="home-card-container">
                        <div className="box box-wrap">
                            <CardInstance
                                title="Discord"
                                img="fa-brands fa-discord text-background"
                                miniDesc="Rejoindre le serveur"
                                miniIcon="fa-solid fa-up-right-from-square text-background"
                                link="https://discord.gg/UhYj8xAR3e"
                            >
                                Vous pouvez rejoindre notre serveur Discord centralisant toutes les annonces et informations concernant nos différentes filières. Un support client est également disponible 24h/24 pour répondre à vos questions et vous aider en temps réel.
                            </CardInstance>
                        </div>

                        <div className="box box-wrap">
                            <CardInstance
                                title="Instagram"
                                img="fa-brands fa-instagram text-background"
                                miniDesc="Accéder au compte"
                                miniIcon="fa-solid fa-up-right-from-square text-background"
                                link="https://instagram.com/owntech_fr"
                            >
                                Rejoignez-nous sur Instagram pour voir les photos de nos événements, vos témoignages, nos bons plans et suivre l'actualité de OwnTech en direct.
                            </CardInstance>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    );
}

export default Home;