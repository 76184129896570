import React, {useReducer, useState} from 'react';
import {getToken, setToken} from "../../functions/CookieUtils";
import {Utils} from "../../functions/Utils";
import {TypeRequest} from "../../functions/TypeRequest";

function PanelProfile({ userData, setUserData, setNot }) {

    const [ignored, forceUpdate] = useReducer(x => x + 1, 0);

    const [firstname, setFirstname] = useState("");
    const [name, setName] = useState("");
    const [orga, setOrga] = useState("");

    const [pass1, setPass1] = useState("");
    const [pass2, setPass2] = useState("");

    const [isInEditing, setIsInEditing] = useState(false);
    const [isPassFTP, setIsPassFTP] = useState(false);
    const [isInUnLink, setIsInUnLink] = useState(false);

    async function changeValue(target, value) {
        switch (target) {
            case "firstname":
                setFirstname(value);
                break;
            case "name":
                setName(value);
                break;
            case "orga":
                setOrga(value)
                break;
            case "pass1":
                setPass1(value);
                break;
            case "pass2":
                setPass2(value);
                break;
            default:
                return;
        }
    }

    async function sendModification() {
        if (!isInEditing) {
            setIsInEditing(true);

            let info = {};

            info.name = name;
            info.firstName = firstname;
            info.orga = orga;
            info.pass1 = pass1;
            info.pass2 = pass2;

            let requestData = {};

            requestData.token = await getToken();

            if (info.name !== "") {
                requestData.newName = info.name;
            }

            if (info.firstName !== "") {
                requestData.newFirstName = info.firstName;
            }

            if (info.orga !== "") {
                requestData.newOrga = info.orga;
            }

            if (info.pass1 !== "") {
                if (info.pass1 === info.pass2) {
                    if (pass1.length < 6 || pass1.length > 32) {
                        setNot(Utils.createNot("no", "Modification", "La taille de votre mot de passe doit-être compris entre 6 et 32 caractères."));

                        await Utils.wait(2500);

                        setNot(Utils.removeNot());
                    } else {
                        requestData.newPass = await Utils.sha256(info.pass1);
                    }
                }
            }

            const response = await Utils.requestApi(TypeRequest.OwnTech,"editAccount", requestData);

            if (response["out"]["valid"] === 1) {

                let numberMod = 0;
                let textMod = "";

                if (response["out"]["firstNameEdit"]) {
                    numberMod++;
                    textMod += " Prénom";

                    userData.firstName = info.firstName;
                }

                if (response["out"]["nameEdit"]) {
                    numberMod++;
                    textMod += " Nom";

                    userData.name = info.name;
                }

                if (response["out"]["orgaEdit"]) {
                    numberMod++;
                    textMod += " Entreprise";

                    userData.orga = info.orga;
                }

                if (response["out"]["passEdit"]) {
                    numberMod++;
                    textMod += " Mot de passe";

                    await setToken(response["out"]["newToken"]);
                }

                if (numberMod > 0) {
                    setNot(Utils.createNot("yes", "Information", "Modification du profil effectuée ! (" + textMod + " )"));

                    setUserData(userData);
                    forceUpdate()
                } else {
                    setNot(Utils.createNot("yes", "Information", "Aucune modification ont été nécessaires."));
                }
            }
            else {
                setNot(Utils.createNot("yes", "Erreur", "Les modifications n'ont pas été effectuées !"));
            }

            await Utils.wait(2500);

            setNot(Utils.removeNot());
            setIsInEditing(false);
        }
    }

    async function editPasswordFTP() {
        if (!isPassFTP) {
            setIsPassFTP(true);

            const response = await Utils.requestApi(TypeRequest.OwnHeberg,"genNewFTPPassword", { token: getToken() });

            if (response["out"]["valid"] === 1) {
                setNot(Utils.createNot("yes", "Information", "Votre nouveau mot de passe SFTP vient de vous être envoyé par mail."));
            }
            else if (response["out"]["access"] === "denied") {
                setNot(Utils.createNot("no", "Requête refusée", "Vous devez attendre 24h après la dernière requête pour générer un nouveau mot de passe."));

                await Utils.wait(1000);
            }
            else {
                setNot(Utils.createNot("no", "Erreur", "Votre mot de passe SFTP n'a pas été modifié."));
            }

            await Utils.wait(2500);

            setNot(Utils.removeNot());
            setIsPassFTP(false);
        }
    }

    async function unlinkAccount() {
        if (!isInUnLink) {
            setIsInUnLink(true);

            const response = await Utils.requestApi(TypeRequest.OwnTech,"unlinkDiscord", { token: getToken() });

            if (response["out"]["valid"] === 1) {
                setNot(Utils.createNot("yes", "Information", "Votre compte a été délié avec succès."));

                userData.discordId = null;
                setUserData(userData);
            }
            else {
                setNot(Utils.createNot("no", "Erreur", "Votre compte n'a pas été délié."));
            }

            await Utils.wait(2500);

            setNot(Utils.removeNot());
            setIsInUnLink(false);
        }
    }

    if (userData === null || setUserData === null) {
        return (
            <span>Chargement de vos informations <i className="fa-solid fa-sync fa-spin"></i></span>
        );
    } else {

        const dateCreated = new Date(userData.created * 1000);
        const options = { weekday: "long", year: "numeric", month: "long", day: "numeric" };

        return (
            <div className="box box-col profile-container">
                <div className="case-40 info-box">
                    <div className="brand">
                        <i className="fa-solid fa-user"></i>Informations
                    </div>

                    <hr />

                    <div className="edit-card">

                        <div className="box box-lm-col">
                            <div className="case-auto">
                                <h3>Bienvenue {userData.pseudo},</h3>

                                <div className="edit-element">
                                    <p>Inscrit depuis <strong>{dateCreated.toLocaleDateString("fr-FR", options)}</strong>,</p>
                                    <p>Vous possédez <strong>{userData.pb}</strong> crédit(s),</p>
                                    <p>Votre adresse email est <strong>{userData.email}</strong>.</p>
                                </div>
                            </div>

                            <div className="case-auto">
                                <h3>Votre compte Discord,</h3>

                                <div className="edit-element">
                                    {userData.discordId === null ?
                                        <>
                                            <p><i className="fa-solid fa-xmark sur-error"></i>Vous n'avez pas encore lié votre compte Discord, utiliser la commande <strong>/link</strong> sur le serveur Discord.</p>
                                            <a className="btn-xs btn-oh-black" href>Rejoindre le serveur Discord</a>
                                        </>
                                        :
                                        <>
                                            <p><i className="fa-solid fa-check sur-good"></i>Votre compte OwnTech est lié à l'id : <strong>{userData.discordId}</strong>.</p>
                                            {isInUnLink ?
                                                <a className="btn-xs btn-oh-black" href><i className="fa-solid fa-sync fa-spin"></i></a>
                                                :
                                                <a className="btn-xs btn-oh-black" onClick={unlinkAccount} href>Révoquer la liaison</a>
                                            }
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="case-auto info-box">
                    <div className="brand">
                        <i className="fa-solid fa-pen-to-square"></i>Modifier votre profil
                    </div>

                    <hr />

                    <div className="edit-card">
                        <h3>Informations de connexion</h3>

                        <div className="edit-element">
                            <p>Pour changer votre adresse e-mail ou votre pseudo, veuillez envoyer une demande par e-mail à notre service clientèle.</p>
                        </div>
                    </div>

                    <div className="edit-card">
                        <h3>Informations personnelles</h3>

                        <div className="edit-element">
                            <p>Prénom</p>
                            <input name="firstname" type="text" className="input input-oh-black" placeholder={userData.firstName} onChange={e => changeValue(e.target.name, e.target.value)} disabled={isInEditing} />
                        </div>

                        <div className="edit-element">
                            <p>Nom</p>
                            <input name="name" type="text" className="input input-oh-black" placeholder={userData.name} onChange={e => changeValue(e.target.name, e.target.value)} disabled={isInEditing} />
                        </div>

                        <div className="edit-element">
                            <p>Votre entreprise</p>
                            <input name="orga" type="text" className="input input-oh-black" placeholder={userData.orga} onChange={e => changeValue(e.target.name, e.target.value)} disabled={isInEditing} />
                        </div>

                        <div className="edit-element">
                            <p>Modifier mot de passe</p>
                            <input name="pass1" type="password" className="input input-oh-black" placeholder="Mot de passe" onChange={e => changeValue(e.target.name, e.target.value)} pattern="\w{6,32}" disabled={isInEditing} />
                            <input name="pass2" type="password" className="input input-oh-black" placeholder="Répéter le mot de passe" onChange={e => changeValue(e.target.name, e.target.value)} pattern="\w{6,32}" disabled={isInEditing} />
                            <h5>La modification de votre mot de passe déconnectera tous les appareils connectés à votre compte.</h5>
                        </div>

                        <div className="edit-valid edit-element">
                            {isInEditing ?
                                <a className="btn btn-oh-black" href><i className="fa-solid fa-sync fa-spin"></i></a>
                                :
                                <a className="btn btn-oh-black" onClick={sendModification} href>Valider les modifications</a>
                            }
                        </div>

                        <hr />

                        <h3>Modification du mot de passe SFTP</h3>

                        <div className="edit-element">
                            <p>Vous pouvez demander la réinitialisation de votre mot de passe SFTP en cliquant sur le bouton ci-dessous.</p>
                            <p>Notez que vous ne pouvez effectuer cette action qu'une fois par jour.</p>
                            <p>Vous recevrez votre nouveau mot de passe par email.</p>
                            {isPassFTP ?
                                <a className="btn btn-oh-black" href><i className="fa-solid fa-sync fa-spin"></i></a>
                                :
                                <a className="btn btn-oh-black" onClick={editPasswordFTP}>Modification du mot de passe SFTP</a>
                            }
                        </div>
                    </div>

                </div>
            </div>
        );
    }

}

export default PanelProfile;