import React, {useReducer, useState} from 'react';
import {Utils} from "../../functions/Utils";
import {TypeRequest} from "../../functions/TypeRequest";
import {getToken} from "../../functions/CookieUtils";
import CardService from "../Cards/CardService";
import PaypalButton from '../PaypalButton';
import {NavLink} from "react-router-dom";


function PanelRenew({ userData, instanceUuid, setUserData, setNot }) {

    const [inPaid, setInPaid] = useState(false);

    const [instance, setInstance] = useState(null);
    const [month, setMonth] = useState(1);
    const [ignored, forceUpdate] = useReducer(x => x + 1, 0);

    async function getInstance() {
        const response = await Utils.requestApi(TypeRequest.OwnHeberg, "getPersonalInstance", { "token": getToken(), "shortUuid": instanceUuid });
        setInstance(response.out);
    }

    if (instance === null)
        getInstance().then();

    if (instance === null || userData === null) {
        return (
            <div className="panel-instance">
                <div className="instance-loading">
                    <span>Chargement du renouvellement de votre service<i className="fa-solid fa-sync fa-spin"></i></span>
                </div>
            </div>
        );
    } else {

        if (instance.validInstance) {
            const instanceName = instance["instanceInfo"]["name"] ?? "Service sans nom";
            const supportId = instance["instanceInfo"]["support_id"];
            const description = instance["instanceInfo"]["description"];
            const remaining = instance["instanceInfo"]["remaining"] ?? null;
            const status = instance["instanceInfo"]["status"];

            const temp = {
                instanceName: instanceName,
                supportId: supportId,
                description: description,
                remaining: remaining,
                status: status
            };

            const endDate = new Date();
            const options = { weekday: "long", year: "numeric", month: "long", day: "numeric", hour: "numeric", minute: "numeric" };

            endDate.setSeconds(endDate.getSeconds() + temp.remaining);

            const pricePerMonth = instance["pricePerMonth"];
            const dataPayment = {
                uuid: instanceUuid,
                price: (month * pricePerMonth).toFixed(2),
                month: month
            };

            function setSelectedMonth(e, data) {
                setMonth(data);
                dataPayment.price = (month * pricePerMonth).toFixed(2);
                dataPayment.month = month;
                forceUpdate()
            }

            let isInPaid = false;
            async function paidWithCredit() {
                if (!isInPaid) {
                    setInPaid(true);

                    const response = await Utils.requestApi(TypeRequest.OwnHeberg, "paidWithPb",
                        {
                            "token": getToken(),
                            "typeBuy": "renew",
                            "offerToken": instanceUuid,
                            "month": month
                        });

                    if (response.out.valid === 1) {
                        setNot(Utils.createNot("yes", "Information", "Renouvellement effectué ! Redirection vers le panel des services ..."));

                        userData.pb = parseInt(userData.pb) - parseInt((dataPayment.price * 100).toFixed());
                        setUserData(userData);
                        forceUpdate()

                        await Utils.wait(3000);

                        window.history.back();
                    } else {
                        setNot(Utils.createNot("no", "Erreur", "Erreur lors du renouvellement, essayez utlérieurement ou utilisez un autre moyen de paiement."));
                    }

                    await Utils.wait(2500);

                    setNot(Utils.removeNot());
                    setInPaid(false);
                }
            }

            return (
                <div className="panel-instance">
                    <h3>Vous êtes sur le point de renouveler ce service</h3>
                    <h4 className="mini-title">Expire {endDate.toLocaleDateString("fr-FR", options)}</h4>

                    <ul className="instance-list">
                        <CardService service={temp} isRenew={false} panel={false} />
                    </ul>

                    <h3>Choisissez votre moyen de paiement</h3>

                    <div className="box box-lm-col">
                        <div className="case-auto credit-case">
                            <div className="credit-title">
                                <i className="fa-solid fa-calendar-days"></i>Pour combien de mois
                            </div>

                            <hr />

                            <div className="box box-lm-col text-center-align month-margin">

                                {Array.from(Array(6)).map((e, i) => (
                                    <div className="case-auto select-month">
                                        <a onClick={((e) => setSelectedMonth(e, i+1))} className="btn-xs btn-oh-dark-two">{i+1} mois</a>
                                    </div>
                                ))}

                            </div>

                        </div>
                    </div>

                    <div className="box box-lm-col">
                        <div className="case-auto credit-case">
                            <div className="credit-title">
                                <i className="fa-solid fa-list-check"></i>Récapitulatif
                            </div>

                            <hr />

                            <p className="text-center-align">Prix <strong>{pricePerMonth} €</strong> TTC / mois.</p>
                            <p className="text-center-align">Vous avez choisi de renouveler votre service pour <strong>{month} mois</strong>.</p>
                        </div>
                    </div>

                    <div className="box box-lm-col">
                        <div className="case-auto credit-case">
                            <div className="credit-title">
                                <i className="fa-brands fa-paypal"></i>Paiement par PayPal
                            </div>

                            <hr />

                            <p className="text-center-align">Vous vous apprêtez à effectuer un paiement de <strong>{dataPayment.price} €</strong> TTC.</p>

                            {userData &&
                                <PaypalButton type="renew" dataPayment={dataPayment} userData={userData} setUserData={setUserData} forceUpdate={forceUpdate}></PaypalButton>
                            }
                        </div>
                        <div className="case-auto credit-case">
                            <div className="credit-title">
                                <i className="fa-solid fa-cubes-stacked"></i>Paiement par crédit
                            </div>

                            <hr />

                            <p className="text-center-align">Vous vous apprêtez à utiliser <strong>{(dataPayment.price * 100).toFixed()}</strong> crédits.</p>

                            {userData.pb >= parseInt((dataPayment.price * 100).toFixed()) ?
                                    (
                                        <>
                                            <br/>
                                            {inPaid ?
                                                <a className="btn btn-block btn-oh-dark-clicked"><i className="fa-solid fa-sync fa-spin"></i></a>
                                                :
                                                <a className="btn btn-block btn-oh-black" onClick={paidWithCredit}>Renouveller pour {parseInt((dataPayment.price * 100).toFixed())} crédits</a>
                                            }
                                        </>
                                    )
                                :
                                    (
                                        <div className="text-center-align mini-title">
                                            <p>Vous n'avez pas suffisamment de crédits, il vous manque <strong>{(dataPayment.price * 100).toFixed() - userData.pb}</strong> crédit(s).</p>
                                            <NavLink exact to="/panel/credit" className="btn-xs btn-oh-black">Créditer mon compte</NavLink>
                                        </div>
                                    )
                            }

                        </div>
                    </div>
                </div >
            );
        } else {
            return (
                <span className="credit-amount">Vous ne pouvez pas renouveler ce service.</span>
            );
        }
    }

}

export default PanelRenew;