import {TypeRequest} from "./TypeRequest";

export class Utils {

    static #API_URL_OWNHEBERG = process.env.REACT_APP_API_URL_OWNHEBERG;
    static #API_URL_OWNTECH = process.env.REACT_APP_API_URL_OWNTECH;

    static #API_TOKEN = process.env.REACT_APP_API_TOKEN;

    static #isInRequest = false;

    static async requestApi(type, request, options = {}) {

        while (Utils.#isInRequest) {
            await Utils.wait(1);
        }

        Utils.#isInRequest = true;

        let api_url;
        switch (type) {
            case TypeRequest.OwnTech:
                api_url = Utils.#API_URL_OWNTECH;
                break;
            case TypeRequest.OwnHeberg:
                api_url = Utils.#API_URL_OWNHEBERG;
                break;
            default:
                return;
        }

        const data = Object.assign({"API_request" : request, "API_token" : Utils.#API_TOKEN, "service" : "OH"}, options);

        const myInit = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        };

        const response = await fetch(api_url, myInit);

        Utils.#isInRequest = false;

        return await response.json();
    }

    static async sha256(message) {
        // encode as UTF-8
        const msgBuffer = new TextEncoder().encode(message);

        // hash the message
        const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);

        // convert ArrayBuffer to Array
        const hashArray = Array.from(new Uint8Array(hashBuffer));

        // convert bytes to hex string
        return hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
    }

    static wait(ms) {
        return new Promise((resolve) => {
            setTimeout(resolve, ms);
        });
    }

    static strExist(string) {
        return string !== "" && string !== undefined && string !== null;
    }

    static getDomainName(hostName) {
        return hostName.substring(hostName.lastIndexOf(".", hostName.lastIndexOf(".") - 1) + 1);
    }

    static #not;
    static removeNot() {
        return { title: Utils.#not.title, data: Utils.#not.data, class: Utils.#not.class + " not-remove" };
    }

    static createNot(status, title, date) {
        Utils.#not = { title: title, data: date, class: "not not-active not-" + status };
        return { title: Utils.#not.title, data: Utils.#not.data, class: Utils.#not.class };
    }
}