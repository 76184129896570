import React, {useState} from 'react';
import {NavLink} from "react-router-dom";
import {Utils} from "../../functions/Utils";
import {TypeRequest} from "../../functions/TypeRequest";

let number = 0;
let frontBuy = false;
let instanceTypeName = null;

export default function CardProduct({idOffer, instanceType = null, isBuy = false}) {

    frontBuy = isBuy;
    instanceTypeName = instanceType;

    switch(idOffer) {
        case "AI54D4":
            return (
                <Generate
                    name="Zepto"
                    desc="Un petit serveur pour de grandes aventures entre amis ! Pourquoi ne pas ajouter un petit mod d'aventure ? Ce serveur en est capable !"
                    price="6,99"
                    level="1"
                    idOffer={idOffer}
                >
                    <div className="product-list">
                        <p><i className="fa-solid fa-users"></i>Slots illimités</p>
                        <p><i className="fa-solid fa-memory"></i>6 Go RAM dédiée</p>
                        <p><i className="fa-solid fa-microchip"></i>AMD Epyc, 2 Cores @ 3.8 Ghz</p>
                        <p><i className="fa-solid fa-hard-drive"></i>25 Go SSD NVMe</p>
                        <hr />
                        <p><i className="fa-solid fa-server"></i>Base de données <NavLink exact to="/cloud/bdd" className="hovertext" data-hover="Voir l'offre">MySQL Lite</NavLink> offerte</p>
                    </div>
                </Generate>
            );
        case "RRZYTB":
            return (
                <Generate
                    name="Hecto"
                    desc="Idéal pour développer votre communauté Minecraft sans problèmes tout en utilisant des plugins et/ou des mods gourmands en performance."
                    price="15,99"
                    level="2"
                    idOffer={idOffer}
                >
                    <div className="product-list">
                        <p><i className="fa-solid fa-users"></i>Slots illimités</p>
                        <p><i className="fa-solid fa-memory"></i>12 Go RAM dédiée</p>
                        <p><i className="fa-solid fa-microchip"></i>Intel Xeon, 3 Cores @ 4 Ghz</p>
                        <p><i className="fa-solid fa-hard-drive"></i>50 Go SSD NVMe</p>
                        <hr />
                        <p><i className="fa-solid fa-server"></i>Base de données <NavLink exact to="/cloud/bdd" className="hovertext" data-hover="Voir l'offre">MySQL Medium</NavLink> offerte</p>
                    </div>
                </Generate>
            );
        case "CISL7V":
            return (
                <Generate
                    name="Quetta"
                    desc="Pour les administrateurs de serveur exigeants ! La pépite des serveurs Minecraft, idéale pour les grosses communautés déjà bien ancrées."
                    price="25,99"
                    level="3"
                    idOffer={idOffer}
                >
                    <div className="product-list">
                        <p><i className="fa-solid fa-users"></i>Slots illimités</p>
                        <p><i className="fa-solid fa-memory"></i>20 Go RAM dédiée</p>
                        <p><i className="fa-solid fa-microchip"></i>AMD Ryzen 7, 4 Cores @ 4.5 Ghz</p>
                        <p><i className="fa-solid fa-hard-drive"></i>100 Go SSD NVMe</p>
                        <hr />
                        <p><i className="fa-solid fa-server"></i>Base de données <NavLink exact to="/cloud/bdd" className="hovertext" data-hover="Voir l'offre">MySQL Ultime</NavLink> offerte</p>
                    </div>
                </Generate>
            );
        case "T6HTI7":
            return (
                <Generate
                    name={instanceType + " Yocto"}
                    desc={"Pour vos petites applications " + instanceType + " de test comme des API ou de petits bots Discord."}
                    price="1,59"
                    level="1"
                    idOffer={idOffer}
                >
                    <div className="product-list">
                        <p><i className="fa-solid fa-memory"></i>512 Mo RAM dédiée</p>
                        <p><i className="fa-solid fa-microchip"></i>1/2 Core CPU</p>
                        <p><i className="fa-solid fa-hard-drive"></i>2 Go SSD NVMe</p>
                        <p><i className="fa-solid fa-network-wired"></i>1 port dédié</p>
                    </div>
                </Generate>
            );
        case "3GM5RB":
            return (
                <Generate
                    name={instanceType + " Milli"}
                    desc={"Idéal pour héberger vos applications " + instanceType + " de taille moyenne et nécessitant une puissance modérée."}
                    price="2,59"
                    level="2"
                    idOffer={idOffer}
                >
                    <div className="product-list">
                        <p><i className="fa-solid fa-memory"></i>1 Go RAM dédiée</p>
                        <p><i className="fa-solid fa-microchip"></i>1 Core CPU</p>
                        <p><i className="fa-solid fa-hard-drive"></i>5 Go SSD NVMe</p>
                        <p><i className="fa-solid fa-network-wired"></i>2 ports dédié</p>
                    </div>
                </Generate>
            );
        case "982IYI":
            return (
                <Generate
                    name={instanceType + " Zetta"}
                    desc={"Pour vos applications " + instanceType + " de grande envergure qui requièrent une puissance de calcul importante."}
                    price="3,99"
                    level="3"
                    idOffer={idOffer}
                >
                    <div className="product-list">
                        <p><i className="fa-solid fa-memory"></i>1,5 Go RAM dédiée</p>
                        <p><i className="fa-solid fa-microchip"></i>2 Cores CPU</p>
                        <p><i className="fa-solid fa-hard-drive"></i>8 Go SSD NVMe</p>
                        <p><i className="fa-solid fa-network-wired"></i>5 ports dédié</p>
                    </div>
                </Generate>
            );
        case "WX2IAH":
            return (
                <Generate
                    name="MySQL Lite"
                    desc={"Une base de données pour vos petits projets."}
                    price="1,99"
                    level="1"
                    idOffer={idOffer}
                >
                    <div className="product-list">
                        <p><i className="fa-solid fa-server"></i>MariaDB stable</p>
                        <p><i className="fa-solid fa-hard-drive"></i>512 Mo SSD NVMe</p>
                        <p><i className="fa-solid fa-link"></i>2 connexions simultanées</p>
                        <p><i className="fa-solid fa-mug-hot"></i>Aucune autre limitation</p>
                    </div>
                </Generate>
            );
        case "2XIX1M":
            return (
                <Generate
                    name="MySQL Medium"
                    desc={"Idéal pour vos projets nécessitant une base de données puissante."}
                    price="2,99"
                    level="2"
                    idOffer={idOffer}
                >
                    <div className="product-list">
                        <p><i className="fa-solid fa-server"></i>MariaDB stable</p>
                        <p><i className="fa-solid fa-hard-drive"></i>2 Go SSD NVMe</p>
                        <p><i className="fa-solid fa-link"></i>5 connexions simultanées</p>
                        <p><i className="fa-solid fa-mug-hot"></i>Aucune autre limitation</p>
                    </div>
                </Generate>
            );
        case "QBZ43C":
            return (
                <Generate
                    name="MySQL Ultime"
                    desc={"Pour vos plus gros projet nécessitant une base de données puissante."}
                    price="4,99"
                    level="3"
                    idOffer={idOffer}
                >
                    <div className="product-list">
                        <p><i className="fa-solid fa-server"></i>MariaDB stable</p>
                        <p><i className="fa-solid fa-hard-drive"></i>10 Go SSD NVMe</p>
                        <p><i className="fa-solid fa-link"></i>Connexions simultanées illimitées</p>
                        <p><i className="fa-solid fa-mug-hot"></i>Aucune autre limitation</p>
                    </div>
                </Generate>
            );

    }

}
function Generate({name, desc, price, level, children, idOffer}) {

    const [inStock, setInStock] = useState(2);

    number = (number + 1) % 3;

    let start = "";
    switch (level) {
        case "1":
            start = "fa-regular fa-star";
            break;
        case "2":
            start = "fa-solid fa-star-half-stroke";
            break;
        case "3":
            start = "fa-solid fa-star";
            break;
        default:
            start = "";
            break;
    }

    function isStock() {
        if (!frontBuy) {
            if (inStock === 0) {
                return (<a className="btn btn-oh-black">Rupture de stock</a>);
            } else if (inStock === 1) {
                if (instanceTypeName == null) {
                    return (<NavLink exact to={"/panel/buy/" + idOffer} className="btn btn-oh-black">Commander</NavLink>);
                } else {
                    return (<NavLink exact to={"/panel/buy/" + idOffer + "?type=" + instanceTypeName} className="btn btn-oh-black">Commander</NavLink>);
                }
            } else {
                return (<a className="btn btn-oh-black"><i className="fa-solid fa-sync fa-spin"></i></a>);
            }
        }
    }

    async function checkStock() {
        const response = await Utils.requestApi(TypeRequest.OwnHeberg,"getOfferInfo", { offerToken: idOffer });

        if (response["out"]["inStock"]) {
            setInStock(1);
        } else {
            setInStock(0);
        }
    }

    if (!frontBuy)
        checkStock().then();

    return (
        <div className={"case-auto card-product color" + (number + 1)}>
            <div className="product-general">
                <h2 className="title"><i className={start}></i>{name}</h2>

                <h4 className="desc">{desc}</h4>

                <h2 className="price">
                    <span className="text-background">{price}</span>
                    <span className="unity">€/mois</span>
                </h2>

                {isStock()}
            </div>

            <hr />

            <div className="product-information">
                {children}
            </div>
        </div>
    );
}