import React from 'react';
import {NavLink} from "react-router-dom";

export default function Account() {
    return (
        <div className="case-auto case-lm-min bg-oh-blue-mat">
            <div className="first-container">

                <NavLink exact to="/">
                    <img className="mini-logo-logo" alt="Logo de OwnHeberg" src="/img/icon.svg" />
                    <span className="mini-logo-title">OwnHeberg</span>
                </NavLink>
                <p className="mini-login-text no-lm">Votre hébergeur personnalisé, performant et adapté à tous vos besoins !</p>
                <p className="mini-login-text no-lm">Avec OwnTech, vous êtes entre de bonnes mains.</p>

                <div className="login-separator" />

                <div className="box">
                    <div className="case-auto">
                        <NavLink exact to="/">
                            <span className="mini-login-text login-not-margin return-accueil">
                                <i className="fa-solid fa-circle-left" />Retour accueil
                            </span>
                        </NavLink>
                    </div>

                    <div className="case-auto text-right-align cg-text">
                        <NavLink exact to="/cg">
                            <span className="mini-login-text login-not-margin">Conditions générales</span>
                        </NavLink>
                        {" | "}
                        <a href="https://owntech.fr/legal" target="_blank" rel="noreferrer">
                            <span className="mini-login-text login-not-margin">Mentions légales</span>
                        </a>
                    </div>
                </div>

            </div>
        </div>
    );
}