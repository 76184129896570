import React from 'react';
import Header from "../components/Helpers/Header";
import Footer from "../components/Helpers/Footer";
import Title from "../components/Title";

function CGPage({ userData }) {
    return (
        <>
            <Header userData={userData} />

            <div className="home bg-oh-dark-light">

                <Title
                    title="Conditions Générales"
                    desc="Vous trouverez ici les conditions générales d'utilisation et de vente de OwnHeberg, qui s'appliquent à tous les services proposés par OwnHeberg."
                />

                <div className="card">

                    <h1 className="text-background home-title">Conditions Générales d'Utilisation et de Vente</h1>
                    <div className="home-card-container cg">
                        <h2 className="home-title">Préambule</h2>
                        <p>
                            Les présentes conditions générales (dites « CG » regroupant les conditions générale d'utilisation et de vente) ont pour objet l'encadrement juridique des modalités de mise à disposition du site et des services par OwnHeberg et des modalités de vente des offres proposé par OwnHeberg et de définir les conditions d’accès et d’utilisation des services par l'Utilisateur et de définir les conditions d’exécution de la vente.
                            <br />
                            Les présentes CG sont accessibles sur le site depuis la rubrique « CGU CGV » situés en pied de site.
                            <br />
                            Toute inscription ou utilisation du site implique l'acceptation sans aucune réserve ni restriction des présentes CG par l’utilisateur. Lors de l'inscription sur le site via le Formulaire d’inscription, chaque utilisateur accepte expressément les présentes CGU en cochant la case précédant le texte suivant : « J'accepte les conditions générales ».
                            <br />
                            En cas de non-acceptation des CG stipulées dans le présent contrat, l'Utilisateur se doit de renoncer à l'accès des services proposés par le site.
                            <br />
                            OwnHeberg se réserve le droit de modifier unilatéralement et à tout moment le contenu des présentes CGU.
                        </p>

                        <h2 className="home-title">Définitions</h2>
                        <p>
                            La présente clause a pour objet de définir les différents termes essentiels du contrat :
                            <ul>
                                <li>Utilisateur : ce terme désigne toute personne qui utilise le site ou l'un des services proposés par OwnHeberg.</li>
                                <li>Site : il s'agit du site internet ownheberg.com.</li>
                                <li>OwnTech : société française (EURL), dont le siège social est situé au 60 Rue François 1er, 75008 Paris, immatriculée au RCS de Paris sous le numéro 914 905 088, représentée par son gérant, Monsieur Evan Gras.</li>
                                <li>OwnHeberg : filiaire de la société OwnTech proposant des solutions d'hébergement.</li>
                            </ul>
                        </p>

                        <h2 className="home-title">Article 1 : Données personnelles</h2>
                        <p>
                            En France, les données personnelles sont notamment protégées par la loi n° 78-87 du 6 janvier 1978, la loi n° 2004-801 du 6 août 2004, l'article L. 226-13 du Code pénal et la Directive Européenne du 24 octobre 1995.
                            <br />
                            A l'occasion de l'utilisation du site, peuvent êtres recueillies : l'URL des liens par l'intermédiaire desquels l'Utilisateur a accédé au site, l'adresse de protocole Internet (IP) de l'Utilisateur.
                            <br />
                            En tout état de cause OwnHeberg ne collecte des informations personnelles relatives à l'Utilisateur que pour le besoin de certains services proposés par le site. L'Utilisateur fournit ces informations en toute connaissance de cause, notamment lorsqu'il procède par lui-même à leur saisie. Il est alors précisé à l'Utilisateur du site l’obligation ou non de fournir ces informations.
                            <br />
                            Conformément aux dispositions des articles 38 et suivants de la loi 78-17 du 6 janvier 1978 relative à l'informatique, aux fichiers et aux libertés, tout Utilisateur dispose d'un droit d'accès, de rectification, de suppression et d'opposition aux données personnelles le concernant. Pour l'exercer, adressez votre demande à OwnTech la société en charge de OwnHeberg par email : <a href="mailto:contact@owntech.fr">contact@owntech.fr</a> ou par voie postale au siège social de OwnTech.
                            <br />
                            Aucune information personnelle de l'utilisateur du site OwnHeberg n'est publiée à l'insu de l'utilisateur, échangée, transférée, cédée ou vendue sur un support quelconque à des tiers. Seule l'hypothèse du rachat de OwnHeberg et de ses droits permettrait la transmission des dites informations à l'éventuel acquéreur qui serait à son tour tenu de la même obligation de conservation et de modification des données vis à vis de l'utilisateur du site OwnHeberg.
                            <br />
                            Le site est en conformité avec le RGPD.
                        </p>

                        <h2 className="home-title">Article 2 : Cookies</h2>
                        <p>
                            L'Utilisateur est informé que lors de ses visites sur le site, un cookie peut s'installer automatiquement sur son logiciel de navigation.
                            <br />
                            En naviguant sur le site, il les accepte.
                            <br />
                            Un cookie est un élément qui ne permet pas d'identifier l'Utilisateur, mais sert à enregistrer des informations relatives à la navigation de celui-ci sur le site Internet. L'Utilisateur pourra désactiver ce cookie par l'intermédiaire des paramètres figurant au sein de son logiciel de navigation.
                            <br />
                            La navigation sur le site OwnHeberg est susceptible de provoquer l'installation de cookie(s) sur l'ordinateur de l'Utilisateur. Un cookie est un fichier de petite taille, qui ne permet pas l'identification de l'Utilisateur, mais qui enregistre des informations relatives à la navigation d'un ordinateur sur un site. Les données ainsi obtenues visent à faciliter la navigation ultérieure sur le site, et ont également vocation à permettre diverses mesures de fréquentation.
                            <br />
                            Le paramétrage du logiciel de navigation permet d'informer de la présence de cookie(s) et éventuellement, de la refuser de la manière décrite à l'adresse suivante : www.cnil.fr
                            <br />
                            L'Utilisateur peut toutefois configurer le navigateur de son ordinateur pour refuser l'installation des cookies, sachant que le refus d'installation d'un cookie peut entraîner l'impossibilité d'accéder à certains services. Il convient cependant de préciser que le refus d'installation d'un cookie peut entraîner l'impossibilité d'accéder à certains services.
                        </p>

                        <h2 className="home-title">Article 3 : Droit applicable et juridiction compétente</h2>
                        <p>
                            La législation française s'applique au présent contrat. En cas d'absence de résolution amiable d'un litige né entre les parties, les tribunaux français seront seuls compétents pour en connaître.
                            <br />
                            Pour toute question relative à l'application des présentes CGU, vous pouvez joindre l'éditeur aux coordonnées inscrites à l'article 1.
                        </p>

                        <h2 className="home-title">Article 4 : Accessibilité et conditions d'accès aux services</h2>
                        <p>
                            Le site est accessible 24h/24h, 7j/7j, sauf interruption, programmée ou non, pour les besoins de sa maintenance ou en cas de force majeure. En cas de modification, interruption ou suspension des services le site OwnHeberg ne saurait être tenu responsable.
                            <br />
                            OwnHeberg ce resserve le droit de refuser l'accès au site et/ou aux services, sans avoir à en justifier sa décision. Ou en cas de non respect des présentes CGU.
                            <br />
                            L'Utilisateur s'engage à ce que les informations communiquées sur le site soient sincères, exactes et à jour.
                        </p>

                        <h2 className="home-title">Article 5 : Publication par l'Utilisateur</h2>
                        <p>
                            Les Utilisateurs s'engage à ne pas publier sur leurs offres d'hébergement des contenus illicite, contraire aux bonnes mœurs et aux règles de droit en vigueur, OwnHeberg se réserve le droit de supprimer tout contenu illicite, sans préavis et sans remboursement.
                            <br />
                            OwnHeberg ne sera tenue responsable que d'une obligation de moyens en ce qui concerne la modération des contenus publiés par les Utilisateurs.
                            <br />
                            L'Utilisateur est seul responsable des contenus qu'il publie.
                            <br />
                        </p>

                        <h2 className="home-title">Article 6 : Consommation des resources</h2>
                        <p>
                            En cas d'utilisation abusive des ressources du serveur, OwnHeberg se réserve le droit de suspendre le service de l'utilisateur sans préavis.
                            <br />
                            Le debit n'est pas limité sur nos offres, mais OwnHeberg se réserve le droit de limiter cette dernière en cas d'utilisation abusive impactant les autres utilisateurs.
                            <br />
                            Les utilisateurs sont tenus de ne pas utiliser les ressources du serveur pour des activités illégales. Comme le minage de crypto-monnaie, le spam, le DDOS, etc.
                        </p>

                        <h2 className="home-title">Article 7 : Description des services fournis</h2>
                        <p>
                            OwnHeberg s'efforce de fournir sur le site OwnHeberg des informations aussi précises que possible. Il ne pourra être tenue responsable des omissions, des inexactitudes et des carences dans la mise à jour, qu'elles soient de son fait ou du fait des tiers partenaires qui lui fournissent ces informations.
                            <br />
                            Toutes les informations indiquées sur le site OwnHeberg sont données à titre indicatif, et sont susceptibles d'évoluer. Par ailleurs, les renseignements figurant sur le site OwnHeberg ne sont pas exhaustifs. Ils sont donnés sous réserve de modifications ayant été apportées depuis leur mise en ligne.
                        </p>

                        <h2 className="home-title">Article 8 : Limitation contractuelles sur les données techniques</h2>
                        <p>
                            Le site utilise la technologie JavaScript.
                            <br />
                            Le site Internet ne pourra être tenu responsable de dommages matériels liés à l'utilisation du site. De plus, l'Utilisateur du site s'engage à accéder au site en utilisant un matériel récent, ne contenant pas de virus et avec un navigateur de dernière génération mis-à-jour
                        </p>

                        <h2 className="home-title">Article 9 : Propriété intellectuelle et contrefaçons</h2>
                        <p>
                            OwnHeberg est propriétaire des droits de propriété intellectuelle ou détient les droits d'usage sur tous les éléments accessibles sur le site, notamment les textes, images, graphismes, logo, icônes, sons, logiciels.
                            <br />
                            Toute reproduction totale ou partielle du site OwnHeberg, représentation, modification, publication, adaptation totale ou partielle de l'un quelconque des éléments mentionnés ci-dessus, quel que soit le moyen ou le procédé utilisé, est interdite, sauf autorisation écrite préalable de : OwnTech, à l'exception des éléments expressément autorisés par la législation en vigueur.
                            <br />
                            Toute exploitation non autorisée du site ou de l'un quelconque des éléments qu'il contient sera considérée comme constitutive d'une contrefaçon et poursuivie conformément aux dispositions des articles L.335-2 et suivants du Code de Propriété Intellectuelle.
                        </p>

                        <h2 className="home-title">Article 10 : Liens hypertextes</h2>
                        <p>
                            Le site OwnHeberg contient un certain nombre de liens hypertextes vers d'autres sites (partenaires, informations...) mis en place avec l'autorisation de OwnHeberg. Cependant, OwnHeberg n'a pas la possibilité de vérifier le contenu des sites ainsi visités, et n'assumera en conséquence aucune responsabilité de ce fait.
                        </p>

                        <h2 className="home-title">Article 11 : Offres, Durée, renouvellement, resiliation</h2>
                        <p>
                            La durée des offres par defaut est de 1 mois, renouvelable manuellement.
                            <br />
                            Les offres ne sont pas remboursables.
                            <br />
                            Le contract de location est régi par les présentes conditions générales.
                            <br />
                            Le renouvellement d'une offre est possible manuellement par l'utilisateur pendant une durée minimum de 1 mois.
                            <br />
                            Les offres de services présentées par OwnHeberg constituent une invitation à faire une offre. L'Utilisateur est libre de refuser l'offre, et ce, sans avoir à justifier de motifs ou de frais.
                            <br />
                            Le site internet constitue un catalogue en ligne des services proposés par OwnHeberg.
                            <br />
                            OwnHeberg se réserve le droit de modifier les offres de services à tout moment, étant précisé que les services proposés seront facturés sur la base des tarifs en vigueur au moment de la commande, sous réserve de disponibilité.
                        </p>

                        <h2 className="home-title">Article 12 : Condition de paiement</h2>
                        <p>
                            Le service de paiement est fourni et securisé par Paypal. Le paiement est effectué à la commande et sont effectués en euros.
                        </p>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
}

export default CGPage;