import React, {useEffect, useState} from 'react';
import {BrowserRouter, Route, Routes as Switch} from 'react-router-dom';
import {Utils} from "./functions/Utils";

import NotFoundPage from "./pages/NotFoundPage";

import Home from "./pages/Home";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Cloud from "./pages/Cloud";
import Panel from "./pages/Panel";
import {getToken, removeToken} from "./functions/CookieUtils";
import {TypeRequest} from "./functions/TypeRequest";
import CGPage from "./pages/CGPage";
import DiscordLink from "./pages/DiscordLink";
import PanelBDD from "./pages/PanelBDD";
import Forgot from "./pages/Forgot";

function App() {

    const [userData, setUserData] = useState(null);

    useEffect(() => {
        async function getUserData() {
            const token = await getToken();

            const notConnected = {
                "connected": false
            };

            if (Utils.strExist(token)) {
                const userDataReq = await Utils.requestApi(TypeRequest.OwnTech, "isConnected", { "token": token });

                if (userDataReq["out"]["connected"] === 1) {
                    userDataReq["out"]["connected"] = true;
                    setUserData(userDataReq["out"]);
                } else {
                    setUserData(notConnected);

                    await removeToken();
                    window.location.reload();
                }
            } else {
                setUserData(notConnected);
            }
        }

        async function addVisit() {
            const development = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
            if (!development) {
                Utils.requestApi(TypeRequest.OwnTech, "addVisit", { "service": "oh", "page": window.location.pathname }).then();
            }
        }

        getUserData().then();
        addVisit().then();
    }, []);

    function userPanel(target) {
        return Utils.strExist(getToken()) ? <Panel userData={userData} setUserData={setUserData} /> : target;
    }

    function bddPanel(target) {
        return Utils.strExist(getToken()) ? <PanelBDD userData={userData} setUserData={setUserData} /> : target;
    }

    return (
        <BrowserRouter>
            <Switch>
                <Route exact path="/" element={<Home userData={userData} />} />
                <Route exact path="/home" element={<Home userData={userData} />} />
                <Route exact path="/p/:code" element={<Home userData={userData} />} />

                <Route exact path="/login" element={userPanel(<Login />)} />
                <Route exact path="/register" element={userPanel(<Register />)} />
                <Route exact path="/forgot" element={userPanel(<Forgot />)} />
                <Route exact path="/discordLink/:discordToken" element={<DiscordLink />} />

                <Route exact path="/cloud" element={<Cloud userData={userData} />} />
                <Route exact path="/cloud/:offer" element={<Cloud userData={userData} />} />
                <Route exact path="/cloud/:offer/:type" element={<Cloud userData={userData} />} />

                <Route exact path="/panel/bdd/:uuid" element={bddPanel(<Login />)} />
                <Route exact path="/panel/bdd/:uuid/:page" element={bddPanel(<Login />)} />

                <Route exact path="/panel" element={userPanel(<Login />)} />
                <Route exact path="/panel/:page" element={userPanel(<Login />)} />
                <Route exact path="/panel/:page/:data" element={userPanel(<Login />)} />

                <Route exact path="/cg" element={<CGPage userData={userData} />} />

                <Route exact path="/*" element={<NotFoundPage userData={userData} />} />
            </Switch>
        </BrowserRouter>
    );
}

export default App;