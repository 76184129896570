import React, {useState} from 'react';
import {Utils} from "../../functions/Utils";
import {TypeRequest} from "../../functions/TypeRequest";
import {getToken} from "../../functions/CookieUtils";
import CardService from "../Cards/CardService";
import {NavLink} from "react-router-dom";

function PanelInstance() {

    const [instance, setInstance] = useState(null);

    async function getInstanceList() {
        const response = await Utils.requestApi(TypeRequest.OwnHeberg, "getInstance", { "token":  getToken()});
        setInstance(response["out"]);
    }

    if (instance === null)
        getInstanceList().then();

    return (
        <div className="panel-instance">
            <GenerateInstanceStyle instance={instance} />
        </div>
    );
}

function GenerateInstanceStyle(props) {

    const { instance } = props;

    let count = 0;
    let services =
        {
            owner: {
                minecraft: [],
                bdd: [],
                web: [],
                instance: [],
                custom: []
            },
            perm: {
                minecraft: [],
                bdd: [],
                web: [],
                instance: [],
                custom: []
            },
            count: {
                minecraft: 0,
                bdd: 0,
                web: 0,
                instance: 0,
                custom: 0
            }
        };

    if (instance !== null) {
        const instanceCount = instance["instanceCount"];

        for (let i = 1; i <= instanceCount; i++) {
            const instanceName = instance[i]["name"] ?? "Service sans nom";
            const supportId = instance[i]["support_id"];
            const description = instance[i]["description"];
            const remaining = instance[i]["remaining"] ?? null;
            const status = instance[i]["status"];
            const type = instance[i]["type"];

            const temp = {
                instanceName: instanceName,
                supportId: supportId,
                description: description,
                remaining: remaining,
                status: status,
                type: type
            };

            switch (instance[i]["type"]) {
                case "mc":
                    if (instance[i]["owner"] === 1)
                        services.owner.minecraft.push(temp);
                    else
                        services.perm.minecraft.push(temp);
                    services.count.minecraft++;
                    break;
                case "ins":
                    if (instance[i]["owner"] === 1)
                        services.owner.instance.push(temp);
                    else
                        services.perm.instance.push(temp);
                    services.count.instance++;
                    break;
                case "bdd":
                    if (instance[i]["owner"] === 1)
                        services.owner.bdd.push(temp);
                    else
                        services.perm.bdd.push(temp);
                    services.count.bdd++;
                    break;
                default:
                    if (instance[i]["owner"] === 1)
                        services.owner.custom.push(temp);
                    else
                        services.perm.custom.push(temp);
                    services.count.custom++;
                    break;
            }

            count++;
        }

        return (
            <>
                {count <= 0 ?
                    <p>Vous n'avez pas de service actif.</p>
                    :
                    <>

                        {services.count.minecraft > 0 &&
                            <>
                                <h2>Serveur{services.count.minecraft > 1 && "s"} Minecraft</h2>
                                {services.owner.minecraft.length > 0 &&
                                    <ul className="instance-list">
                                        {services.owner.minecraft.map(service => {
                                            return <CardService service={service} isRenew={true} panel={true} />;
                                        })}
                                    </ul>
                                }

                                {services.perm.minecraft.length > 0 &&
                                    <ul className="instance-list">
                                        <p>Partagé{services.perm.minecraft.length > 1 && "s"}</p>
                                        {services.perm.minecraft.map(service => {
                                            return <CardService service={service} isRenew={false} panel={true} />;
                                        })}
                                    </ul>
                                }
                            </>
                        }

                        {services.count.instance > 0 &&
                            <>
                                <h2>Instance{services.count.instance > 1 && "s"}</h2>
                                {services.owner.instance.length > 0 &&
                                    <ul className="instance-list">
                                        {services.owner.instance.map(service => {
                                            return <CardService service={service} isRenew={true} panel={true} />;
                                        })}
                                    </ul>
                                }

                                {services.perm.instance.length > 0 &&
                                    <ul className="instance-list">
                                        <p>Partagé{services.perm.instance.length > 1 && "s"}</p>
                                        {services.perm.instance.map(service => {
                                            return <CardService service={service} isRenew={false} panel={true} />;
                                        })}
                                    </ul>
                                }
                            </>
                        }

                        {services.count.bdd > 0 &&
                            <>
                                <h2>Base{services.count.instance > 1 && "s"} de données</h2>
                                {services.owner.bdd.length > 0 &&
                                    <ul className="instance-list">
                                        {services.owner.bdd.map(service => {
                                            return <CardService service={service} isRenew={true} panel={true} />;
                                        })}
                                    </ul>
                                }

                                {services.perm.bdd.length > 0 &&
                                    <ul className="instance-list">
                                        <p>Partagé{services.perm.bdd.length > 1 && "s"}</p>
                                        {services.perm.bdd.map(service => {
                                            return <CardService service={service} isRenew={false} panel={true} />;
                                        })}
                                    </ul>
                                }
                            </>
                        }

                        {services.count.custom > 0 &&
                            <>
                                <h2>Service{services.count.custom > 1 && "s"} personnalisé</h2>
                                {services.owner.custom.length > 0 &&
                                    <ul className="instance-list">
                                        {services.owner.custom.map(service => {
                                            return <CardService service={service} isRenew={true} panel={true} />;
                                        })}
                                    </ul>
                                }

                                {services.perm.custom.length > 0 &&
                                    <ul className="instance-list">
                                        <p>Partagé{services.perm.custom.length > 1 && "s"}</p>
                                        {services.perm.custom.map(service => {
                                            return <CardService service={service} isRenew={false} panel={true} />;
                                        })}
                                    </ul>
                                }
                            </>
                        }
                    </>
                }

                <a className="btn btn-block btn-oh-black" href="#new"><i className="fa-solid fa-plus"></i> Acheter un nouveau service</a>

                <div id="new" className="overlay">
                    <div className="popup">
                        <a className="close" href="#"><i className="fa-solid fa-xmark"></i></a>

                        <h3>De quoi avez vous besoin ?</h3>

                        <div className="content">
                            <h5>Hébergement</h5>
                            <NavLink exact to="/cloud/minecraft" className="btn btn-block btn-oh-black"><i className="fa-solid fa-cube"></i>Serveur Minecraft</NavLink>
                            <NavLink exact to="/cloud/bdd" className="btn btn-block btn-oh-black"><i className="fa-solid fa-database"></i>Base de données</NavLink>

                            <h5>Instances</h5>
                            <div className="box">
                                <div className="case-auto">
                                    <NavLink exact to="/cloud/instance/nodejs" className="btn btn-oh-black btn-block"><i className="fa-solid fa-server"></i>NodeJS</NavLink>
                                </div>
                                <div className="case-auto">
                                    <NavLink exact to="/cloud/instance/java" className="btn btn-oh-black btn-block"><i className="fa-solid fa-server"></i>Java</NavLink>
                                </div>
                                <div className="case-auto">
                                    <NavLink exact to="/cloud/instance/python" className="btn btn-oh-black btn-block"><i className="fa-solid fa-server"></i>Python</NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
    else {
        return (
            <div className="instance-loading">
                <span>Chargement de vos instances<i className="fa-solid fa-sync fa-spin"></i></span>
            </div>
        );
    }
}

export default PanelInstance;