import React, {useEffect, useState} from 'react';
import ReactDOM from "react-dom"
import {Utils} from "../functions/Utils";
import {TypeRequest} from "../functions/TypeRequest";
import {useNavigate} from "react-router-dom";

const urlServer = process.env.REACT_APP_API_URL_PAYMENT;
const apiClientToken = process.env.REACT_APP_API_URL_PAYMENT_CLIENT;

const PaypalButton = ({ type, dataPayment, userData, setUserData, forceUpdate }) => {
    const [PayPalButton, setPayPalButton] = useState(null)

    useEffect(() => {
        const script = document.createElement('script');
        script.src = `https://www.paypal.com/sdk/js?client-id=${apiClientToken}&enable-funding=venmo&currency=EUR`;
        script.async = true;
        document.body.appendChild(script);

        script.onload = () => {
            setPayPalButton(() =>
                window.paypal.Buttons.driver("react", { React, ReactDOM })
            );
        }
        return () => {
            document.body.removeChild(script);
        }
    }, []);

    const navigate = useNavigate();

    const [not, setNot] = useState({
        class: "",
        title: "",
        data: ""
    });

    async function verifyPayment(validate) {
        if (validate) {
            setNot(Utils.createNot("yes", 'Information', "Vérification de votre paiement en cours..."));

            const response = await Utils.requestApi(TypeRequest.OwnHeberg, "verifyPayment", { "id": userData.userID });

            let thxText = "Merci pour votre achat !";

            const pb = response["out"]["amount"];
            if (pb > 0) {
                thxText += " " + pb + " crédits on été ajoutés à votre compte.";

                userData.pb = parseInt(userData.pb) + parseInt(pb);
                setUserData(userData);
                forceUpdate()
            }

            const renew = response["out"]["renew"];
            if (renew > 0) {
                thxText += " Votre service vient d'être correctement renouvelée de " + dataPayment.month + " mois.";
            }

            const newInstance = response["out"]["new"];
            if (newInstance > 0) {
                thxText += " Votre serveur est en cours de création...";
            }

            setNot(Utils.createNot("yes", 'Information', thxText));

            await Utils.wait(4000);

            if (type === "renew") {
                window.history.back()
            } else if (type === "new") {
                navigate("/panel/instance", { replace: true });
                window.location.reload();
            }
        } else {
            setNot(Utils.createNot("no", "Erreur", "Aucun paiement n'a été trouvé."));
        }

        await Utils.wait(2500);

        setNot(Utils.removeNot());
    }

    const createOrder = async (data, actions) => {

        let amount = 0;

        if (type === "pb")
            amount = dataPayment / 100.00;
        else if (type === "renew" || type === "new")
            amount = dataPayment.price;

        if (type === "new") {
            const response = await Utils.requestApi(TypeRequest.OwnHeberg,"getOfferInfo", { offerToken: dataPayment.instanceUUID });

            if (!response["out"]["inStock"]) {
                return null;
            }
        }

        return fetch(`${urlServer}api/orders`, {
            method: "post",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({

                client_id: userData.userID,
                amount: amount,
                type: type,
                information: type === "pb" ? amount : dataPayment,
                method: "PaypalAccount",
                service: "OH"
            }),
        })
            .then((response) => response.json())
            .then((order) => order.id);

    };
    const onApprove = (data, actions) => {
        return fetch(`${urlServer}api/orders/${data.orderID}/capture/ownheberg`, {
            method: "post",
        })
            .then((response) => response.json())
            .then((orderData) => {
                const transaction = orderData.purchase_units[0].payments.captures[0];
                verifyPayment(transaction["status"] === "COMPLETED").then();
            });
    }

    return (
        <>
            <div className={not.class}>
                <span className="not-title">{not.title}</span>
                <span className="not-data">{not.data}</span>
            </div>

            <div className="paypal-button">
                {PayPalButton ?
                    <PayPalButton
                        createOrder={(data, actions) => createOrder(data, actions)}
                        onApprove={(data, actions) => onApprove(data, actions)}
                        style={{ layout: 'horizontal', tagline: 'false' }}
                    />
                    : null}
            </div>
        </>
    );
};

export default PaypalButton;