import React, {useState} from 'react';
import {Utils} from "../../functions/Utils";
import {TypeRequest} from "../../functions/TypeRequest";
import {getToken} from "../../functions/CookieUtils";

function PanelHome(props) {

    const { userData } = props;
    const [response, setResponse] = useState(null);

    async function getInfo() {
        const response = await Utils.requestApi(TypeRequest.OwnHeberg, "getHomeInformations", { "token":  getToken()});
        setResponse(response.out);
    }

    if (response === null)
        getInfo().then();

    if (userData === null) {
        return (
            <span>Chargement de vos informations <i className="fa-solid fa-sync fa-spin"></i></span>
        );
    } else {
        return (
            getPanel(userData, response)
        );
    }
}

function getPanel(userData, response) {
    function getStatus() {
        if (userData.orga === "")
            return (
                <p className="particular hovertext" data-hover="Les factures seront éditées à votre nom.">Particulier</p>
            )
        else
            return (
                <p className="pro hovertext" data-hover="Les factures seront éditées au nom de votre société.">Professionnel</p>
            )
    }

    function nextDue() {

        if (response === null) {
            return <i className="fa-solid fa-sync fa-spin"></i>;
        }
        else if (response.nextDue === -1) {
            return "Vous n'avez pas de service.";
        } else {
            const day = Math.round(response.nextDue / 60 / 60 / 24);
            const hour = Math.round(response.nextDue / 60 / 60);

            if (day <= 3) {
                return hour + " heure" + (hour > 1 ? "s" : "");
            }
            else {
                return day + " jours";
            }
        }
    }

    return (
        <div className="home-panel-container">
            <h3 className="title">Bienvenue {userData.firstName ?? userData.pseudo}, voici une vue d'ensemble de votre panel.</h3>

            <div className="box box-lm-col">
                <div className="case-auto info-box">
                    <div className="brand">
                        Votre statut
                    </div>
                    {getStatus()}
                </div>
                <div className="case-auto info-box">
                    <div className="brand">
                        Votre balance
                    </div>
                    {userData.pb} <i className="fa-solid fa-cubes-stacked"></i>
                </div>
                <div className="case-auto info-box">
                    <div className="brand">
                        Prochaine échéance
                    </div>

                    {nextDue()}
                </div>
            </div>

            <div className="box box-lm-col">
                <div className="case-auto info-box">
                    <div className="brand">
                        Service(s)
                    </div>

                    <hr />

                    <div className="box box-lm-col">
                        <div className="case-auto">
                            <div className="brand">
                                Actif
                            </div>

                            {response === null ?
                                <i className="fa-solid fa-sync fa-spin"></i>
                                :
                                response.actif
                            }
                        </div>

                        <div className="case-auto">
                            <div className="brand">
                                Bientôt expiré
                            </div>

                            {response === null ?
                                <i className="fa-solid fa-sync fa-spin"></i>
                                :
                                response.soonExpire
                            }
                        </div>

                        <div className="case-auto">
                            <div className="brand hovertext" data-hover="Une fois expiré, votre service sera supprimé au bout de 4 jours.">
                                Expiré
                            </div>

                            {response === null ?
                                <i className="fa-solid fa-sync fa-spin"></i>
                                :
                                response.expired
                            }
                        </div>

                        <div className="case-auto">
                            <div className="brand">
                                Total
                            </div>

                            {response === null ?
                                <i className="fa-solid fa-sync fa-spin"></i>
                                :
                                response.actif + response.soonExpire + response.expired
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PanelHome;