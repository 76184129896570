import React from 'react';
import Header from "../components/Helpers/Header";
import Footer from "../components/Helpers/Footer";
import Title from "../components/Title";
import {useParams} from "react-router-dom";
import NotFoundPage from "./NotFoundPage";
import CardProduct from "../components/Cards/CardProduct";

export default function Cloud({ userData }) {

    const params = useParams();

    const offer = params.offer ?? null;
    const type = params.type ?? null;

    let title = "";
    let desc = "";
    let ad = [];

    if (offer === "instance") {
        desc = "Une offre haut-de-gamme vous permettant d'héberger n'importe quelle application ou API sans vous soucier des performances.";
        if (type === "java") {
            title = "Instance Java";
        } else if (type === "nodejs") {
            title = "Instance NodeJS";
        } else if (type === "python") {
            title = "Instance Python";
        }

        ad.push({ info: "RAM DDR4 100% dédiée", desc: "Il s'agit de votre mémoire RAM dédiée qui utilise la technologie ECC spécialisée pour sécuriser les données." });
        ad.push({ info: "Compatible avec les bots Discord.", desc: "Vous pouvez héberger vos bots Discord sur nos offres." });
    } else if (offer === "minecraft") {
        title = "Serveur Minecraft";
        desc = "Propulsez votre expérience de jeu à un niveau supérieur avec notre solution d'hébergement haute performance pour serveurs Minecraft. Profitez de la rapidité et de la stabilité de notre hébergement pour un jeu fluide et sans latence !";

        ad.push({ info: "RAM DDR4 100% dédiée", desc: "Il s'agit de votre mémoire RAM dédiée qui utilise la technologie ECC spécialisée pour sécuriser les données." });
        ad.push({ info: "Coeur CPU dédié", desc: "Les coeurs attribués à votre offre vous sont totalement réservés." });
    } else if (offer === "web") {
        title = "Hébergement Web";
        desc = "";

        ad.push({ info: "Trafic illimité", desc: "Aucune limite de bande passante n'est appliquée sur nos offres." });
        ad.push({ info: "Certificat SSL gratuit", desc: "Utilisez le panel pour générer gratuitement vos certificats SSL." });
    } else if (offer === "bdd") {
        title = "Base de données";
        desc = "Hébergez votre base de données sur nos serveurs pour sécuriser vos données et les rendre accessibles n'importe où, à tout moment et rapidement.";

        ad.push({ info: "Sécurité maximale des données", desc: "Vos bases de données sont entièrement sécurisées par des professionnels." });
        ad.push({ info: "Serveur haute performance", desc: "Vos bases de données sont stockées sur des serveurs dédiés et puissants pour assurer leur sécurité et leur performance." });
    }

    ad.push({ info: "Stockage SSD NVMe", desc: "Les SSD NVMe sont en moyenne 2 à 7 fois plus rapides qu'un SSD conventionnel, ce qui améliore la performance de vos applications." });

    return (
        <>
            {title === "" ?
                <NotFoundPage userData={userData} />
                :
                <div>
                    <Header userData={userData} />

                    <div className="home bg-oh-dark-light">

                        <Title title={title} desc={desc}>
                            <div className="info-offer">
                                {ad.map(state => {
                                    return (
                                        <>
                                            <span className="hovertext" data-hover={state.desc}>
                                                <i className="fa-solid fa-check"></i> {state.info}
                                            </span>
                                            <hr />
                                        </>
                                    );
                                })}
                            </div>
                        </Title>

                        <div className="card">
                            <ProductGenerate offer={offer} type={type} />
                        </div>
                    </div>

                    <Footer />
                </div>
            }
        </>
    );
}

function ProductGenerate({offer, type}) {

    if (offer === "minecraft") {
        return (
            <>
                <h1 className="text-background home-title">Nos offres de serveur Minecraft</h1>
                <div className="home-card-container">
                    <div className="box box-lm-col">
                        <CardProduct idOffer="AI54D4" />
                        <CardProduct idOffer="RRZYTB" />
                        <CardProduct idOffer="CISL7V" />
                    </div>
                </div>
            </>
        );
    } else if (offer === "web") {
        return (
            <>
                <h1 className="text-background home-title">Nos offres d'hébergement Web</h1>
                <div className="home-card-container">
                    <div className="box box-lm-col">
                        <h4>Nos offres Web ne sont pas encore disponible.</h4>
                    </div>
                </div>
            </>
        )
    } else if (offer === "bdd") {
        return (
            <>
                <h1 className="text-background home-title">Nos offres de base de données</h1>
                <div className="home-card-container">
                    <div className="box box-lm-col">
                        <CardProduct idOffer="WX2IAH" />
                        <CardProduct idOffer="2XIX1M" />
                        <CardProduct idOffer="QBZ43C" />
                    </div>
                </div>
            </>
        )
    } else if (offer === "instance") {

        let instanceName;
        switch (type) {
            case "java":
                instanceName = "Java";
                break;
            case "nodejs":
                instanceName = "NodeJS";
                break;
            case "python":
                instanceName = "Python";
                break;
        }

        return (
            <>
                <h1 className="text-background home-title">Nos instances {instanceName}</h1>
                <div className="home-card-container">
                    <div className="box box-lm-col">
                        <CardProduct idOffer="T6HTI7" instanceType={instanceName} />
                        <CardProduct idOffer="3GM5RB" instanceType={instanceName} />
                        <CardProduct idOffer="982IYI" instanceType={instanceName} />
                    </div>
                </div>
            </>
        )
    }

}