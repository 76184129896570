import Cookies from "universal-cookie";
import {Utils} from "./Utils";

const cookieUtils = new Cookies();
const domainName = Utils.getDomainName(window.location.hostname);
const allDomainName = "." + domainName;

const timeCookieTokenMS = 4 * 24 * 60 * 60 * 1000;
const timeCookieCodeMS = 30 * 24 * 60 * 60 * 1000;

function setToken(token, remember = false) {
    if (remember) {
        let expire = new Date();
        expire.setTime(expire.getTime() + timeCookieTokenMS);

        cookieUtils.set("token", token, {expires: expire, domain: allDomainName, path: "/"});
    }
    else {
        cookieUtils.set("token", token, {domain: allDomainName, path: "/"});
    }
}

function setCodeCookie(code) {
    let expire = new Date();
    expire.setTime(expire.getTime() + timeCookieCodeMS);
    cookieUtils.set("parrainCode", code, {expires: expire, domain: domainName, path: "/"});
}

function removeToken() {
    cookieUtils.remove("token", { domain: allDomainName, path: "/" });
}

function getToken() {
    return cookieUtils.get("token");
}

function getCode() {
    return cookieUtils.get("parrainCode");
}

export { setToken, removeToken, getToken, setCodeCookie, getCode };