import React, {useState} from 'react';

import PanelHome from "../components/Panel/PanelHome";
import PanelError from "../components/Panel/PanelError";

import {NavLink, useNavigate, useParams} from "react-router-dom";
import {Utils} from "../functions/Utils";
import {removeToken} from "../functions/CookieUtils";
import PanelSupport from "../components/Panel/PanelSupport";
import PanelCredit from "../components/Panel/PanelCredit";
import PanelInstance from "../components/Panel/PanelInstance";
import PanelInvoice from "../components/Panel/PanelInvoice";
import PanelProfile from "../components/Panel/PanelProfile";
import PanelRenew from "../components/Panel/PanelRenew";
import PanelParrain from "../components/Panel/PanelParrain";
import PanelBuy from "../components/Panel/PanelBuy";

function Panel({ userData, setUserData }) {

    const navigate = useNavigate();
    const params = useParams();

    const page = params.page ?? "home";
    const dataPage = params.data ?? null;

    const [not, setNot] = useState({
        class: "",
        title: "",
        data: ""
    });

    async function logOut() {
        setNot(Utils.createNot("yes", "Déconnexion", "Déconnexion en cours ..."));

        await Utils.wait(1500);

        await removeToken();

        await Utils.wait(500);

        navigate("/", { replace: true });
        window.location.reload();
    }

    function getComponent() {
        switch (page) {
            case "home":
                return <PanelHome userData={userData} />;
            case "support":
                return <PanelSupport />;
            case "credit":
                return <PanelCredit userData={userData} setUserData={setUserData} setNot={setNot} />;
            case "instance":
                return <PanelInstance />;
            case "invoice":
                return <PanelInvoice />;
            case "profile":
                return <PanelProfile userData={userData} setUserData={setUserData} setNot={setNot} />;
            case "renew":
                return <PanelRenew userData={userData} instanceUuid={dataPage} setUserData={setUserData} setNot={setNot} />;
            case "parrain":
                return <PanelParrain setNot={setNot} />;
            case "buy":
                return <PanelBuy offerUuid={dataPage} userData={userData} setUserData={setUserData} setNot={setNot} />;

            default:
                return <PanelError />;
        }
    }

    return (
        <div className={userData === null && "waiting"}>

            <span className="waiting-info">Chargement du panel <i className="fa-solid fa-sync fa-spin waiting-icon"></i></span>

            <div className={not.class}>
                <span className="not-title">{not.title}</span>
                <span className="not-data">{not.data}</span>
            </div>

            <div className="nav-panel">
                <ul className="nav-list">
                    <li className="logo">
                        <NavLink exact to="/">
                            <img className="mini-logo-logo" alt="Logo de OwnHeberg" src="/img/icon.svg" />
                            <span className="mini-logo-title">OwnHeberg</span>
                        </NavLink>
                    </li>

                    <li className="route">
                        <NavLink exact to="/panel" className={page === "home" ? "clicked" : ""}>
                            <i className="fa-solid fa-house-user"></i>
                            <span className="links_name">Accueil</span>
                        </NavLink>
                    </li>

                    <li className="route">
                        <NavLink exact to="/panel/instance" className={page === "instance" ? "clicked" : ""}>
                            <i className="fa-solid fa-server"></i>
                            <span className="links_name">Vos services</span>
                        </NavLink>
                    </li>

                    <div className='separator' />

                    {/* TODO Evan : Make Support
                        <li className="route">
                            <NavLink exact to="/panel/support" className={page === "support" ? "clicked" : ""}>
                                <i className="fa-solid fa-ticket"></i>
                                <span className="links_name">Contacter le support</span>
                            </NavLink>
                        </li>
                    */}

                    <li className="route">
                        <NavLink exact to="/panel/credit" className={page === "credit" ? "clicked" : ""}>
                            <i className="fa-solid fa-coins"></i>
                            <span className="links_name">Créditer votre compte</span>
                        </NavLink>
                    </li>

                    <li className="route">
                        <NavLink exact to="/panel/parrain" className={page === "parrain" ? "clicked" : ""}>
                            <i className="fa-solid fa-users-line"></i>
                            <span className="links_name">Parrainage</span>
                        </NavLink>
                    </li>

                    <li className="route">
                        <NavLink exact to="/panel/invoice" className={page === "invoice" ? "clicked" : ""}>
                            <i className="fa-solid fa-file-lines"></i>
                            <span className="links_name">Vos paiements</span>
                        </NavLink>
                    </li>

                    <div className='separator' />

                    <li className="route">
                        <NavLink exact to="/panel/profile" className={page === "profile" ? "clicked" : ""}>
                            <i className="fa-solid fa-user"></i>
                            <span className="links_name">Votre profil</span>
                        </NavLink>
                    </li>

                    <li className="route">
                        <NavLink exact to="/">
                            <i className="fa-solid fa-house"></i>
                            <span className="links_name">Retourner sur le site</span>
                        </NavLink>
                    </li>

                    <li className="route">
                        <NavLink onClick={logOut} to="">
                            <i className="fa-solid fa-right-from-bracket"></i>
                            <span className="links_name">Déconnexion</span>
                        </NavLink>
                    </li>

                    <li className="name">
                        {
                            userData !== null && userData.connected ?
                                <>Connectée en tant que {userData.pseudo}</>
                                :
                                <>Bienvenue</>
                        }
                    </li>
                </ul>
            </div>

            <section className="home-section">
                <TitlePanel page={page} />
                {userData !== null && getComponent()}
            </section>
        </div>
    );
}

function TitlePanel(props) {

    let title, desc;

    switch (props.page) {
        case "home":
            title = "Accueil";
            desc = "Vue global du panel OwnHeberg.";
            break;
        case "support":
            title = "Contacter le support";
            desc = "Vous pouvez ici ouvrir des tickets pour discuter avec le support.";
            break;
        case "credit":
            title = "Créditer votre compte";
            desc = "Acheter des crédits pour recharger vos services, plusieurs moyen de paiement sont disponible.";
            break;
        case "instance":
            title = "Vos services";
            desc = "Gérer et configurer vos différents services.";
            break;
        case "invoice":
            title = "Vos paiements";
            desc = "Vous trouverez la liste de vos paiements effectués et leur factures associé.";
            break;
        case "profile":
            title = "Profil";
            desc = "Vous pouvez modifier ici votre profil.";
            break;
        case "renew":
            title = "Renouvellement";
            desc = "Renouveler votre service.";
            break;
        case "parrain":
            title = "Parrainage";
            desc = "Inviter de nouveaux utilisateurs pour gagner des récompenses.";
            break;
        case "buy":
            title = "Acheter un nouveau service";
            desc = "Vous êtes sur le point d'acheter un nouveau service.";
            break;

        default:
            title = "Erreur";
            desc = "Cette page n'existe pas.";
            break;
    }

    return (
        <div className="panel-title">
            <h1 className="text-background panel-title-block">{title}</h1>
            <span className="page-desc">{desc}</span>
            <hr />
        </div>
    );
}

export default Panel;