import React, {useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {Utils} from "../functions/Utils";
import {getToken} from "../functions/CookieUtils";
import {TypeRequest} from "../functions/TypeRequest";
import Account from "../components/Account";

function DiscordLink() {

    const navigate = useNavigate();
    const params = useParams();

    const discordToken = params.discordToken ?? null;

    const [isInLogin, setIsInLogin] = useState(false);
    const [isInLink, setIsInLink] = useState(false);

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const [tokenLogin, setTokenLogin] = useState("await");
    const [userData, setUserData] = useState(null);

    const [not, setNot] = useState({
        class: "",
        title: "",
        data: ""
    });

    async function loginRequest() {
        if (!isInLogin) {
            setIsInLogin(true);

            const errorText = "Erreur de connexion";

            if (email === "" || password === "") {
                setNot(Utils.createNot("no", errorText, "Vous devez remplir les champs."));
            }
            else {
                const response = await Utils.requestApi(TypeRequest.OwnTech,"loginRequest",
                    {
                        "email": email,
                        "password": await Utils.sha256(password)
                    });

                if (response["out"]["valid"] === 1) {
                    const token = response["out"]["token"];
                    setTokenLogin(token);

                    setNot(Utils.createNot("yes", "Information", "Connexion effectuée !"));
                }
                else if (response["out"]["valid"] === 2) {
                    setNot(Utils.createNot("no", errorText, "Mot de passe ou adresse email incorrecte."));
                }
                else if (response["out"]["valid"] === 3) {
                    setNot(Utils.createNot("no", errorText, "Ceci n'est pas une adresse email valide."));
                }
                else if (response["out"]["valid"] === 4) {
                    setNot(Utils.createNot("no", errorText, "Ceci n'est pas un mot de passe valide."));
                }
                else if (response["out"]["valid"] === 5) {
                    setNot(Utils.createNot("no", "Confirmation adresse e-mail", "Merci de valider votre adresse e-mail depuis le mail qui vient d'être envoyé."));

                    await Utils.wait(2000);
                }
                else {
                    setNot(Utils.createNot("no", errorText, "Une erreur interne est survenue"));
                }
            }

            await Utils.wait(2500);

            setNot(Utils.removeNot());
            setIsInLogin(false);
        }
    }

    async function linkAccount() {
        if (!isInLink) {
            setIsInLink(true);

            if (tokenLogin === null || discordToken === null) {
                setNot(Utils.createNot("no", "Erreur", "Certaines données sont manquantes."));
            }
            else {
                const response = await Utils.requestApi(TypeRequest.OwnTech,"linkDiscord", { "token": tokenLogin, "discordToken": discordToken });

                if (response["out"]["valid"] === 1) {
                    const token = response["out"]["token"];
                    setTokenLogin(token);

                    setNot(Utils.createNot("yes", "Information", "Le compte Discord portant l'id " + response["out"]["idDiscord"] + " vient d'être lié ! Vous pouvez gérer votre compte depuis le panel."));

                    await Utils.wait(4000);

                    navigate("/", { replace: true });
                    window.location.reload();
                }
                else if (response["out"]["valid"] === 2) {
                    setNot(Utils.createNot("no", "Erreur de liaison", "Une erreur à eu lieu lors de la liaison de votre compte, celle ci n'a pas été effectuée."));
                }
                else if (response["out"]["valid"] === 3) {
                    setNot(Utils.createNot("no", "Erreur de connexion", "Les informations transmises au serveur sont incorrecte."));
                }
                else if (response["out"]["valid"] === 4) {
                    setNot(Utils.createNot("yes", "Information", "Ce compte Discord portant l'id " + response["out"]["idDiscord"] + " est déjà lié à ce compte OwnTech."));

                    await Utils.wait(2000);
                }
            }

            await Utils.wait(2500);

            setNot(Utils.removeNot());
            setIsInLink(false);
        }
    }

    async function getDataUser() {
        if (userData === null) {
            const response = await Utils.requestApi(TypeRequest.OwnTech, "isConnected", { "token": tokenLogin });

            if (response["out"]["connected"] === 1) {
                setUserData(response["out"]);
            }
            else {
                setTokenLogin(null);
                setUserData(null);
            }
        }
    }

    if (tokenLogin === "await") {
        if (getToken() !== null) {
            setTokenLogin(getToken());
        }
        else {
            setTokenLogin(null);
        }
    }

    if (tokenLogin !== null && tokenLogin !== "await")
        getDataUser().then();

    return (
        <div>
            <div className={not.class}>
                <span className="not-title">{not.title}</span>
                <span className="not-data">{not.data}</span>
            </div>

            <div className="box box-lm-col box-all">

                <Account />

                <div className="case-auto bg-oh-dark">
                    <div className="login-container">
                        <div className="title">
                            <h2>Relier votre compte Discord</h2>
                            <p>Connectez vous à votre compte <span className="text-background">OwnTech</span> pour le relier à votre compte Discord.</p>
                        </div>

                        { userData === null ?
                            <>
                                <div className="input-container">
                                    <h4>Adresse email <strong>*</strong></h4>
                                    <input name="email" type="text" className="input input-oh-black" placeholder="Adresse email" onChange={e => setEmail(e.target.value)} disabled={isInLogin} />

                                    <h4>Mot de passe <strong>*</strong></h4>
                                    <input name="password" type="password" className="input input-oh-black" placeholder="Mot de passe" onChange={e => setPassword(e.target.value)} disabled={isInLogin} />
                                </div>

                                <div className="end">
                                    {isInLogin ?
                                        <a className="btn btn-oh-dark-clicked" href><i className="fa-solid fa-sync fa-spin"></i></a>
                                        :
                                        <a name="login" onClick={loginRequest} className="btn btn-oh-black" href>Connexion</a>
                                    }
                                </div>
                            </>
                            :
                            <>
                                <div className="input-container">
                                    <h4>Confirmer la liaison.</h4>
                                    <p>Compte OwnTech concerné : <span className="text-background">{userData.pseudo}</span></p>
                                </div>

                                <div className="end">
                                    {isInLink ?
                                        <a className="btn btn-oh-dark-clicked" href><i className="fa-solid fa-sync fa-spin"></i></a>
                                        :
                                        <a name="confirmer" className="btn btn-oh-black btn-block" onClick={linkAccount} href>Valider la liaison</a>
                                    }
                                </div>

                                <div className="end">
                                    {isInLink ?
                                        <a className="btn btn-oh-dark-clicked" href><i className="fa-solid fa-sync fa-spin"></i></a>
                                        :
                                        <a name="chang" className="btn btn-oh-black btn-block" href onClick={() => {
                                            setUserData(null)
                                            setTokenLogin(null)
                                        }}>Changer de compte</a>
                                    }
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DiscordLink;