import React from 'react';

function Title({title, desc, children}) {

    return (
        <div className="header-title bg-oh-blue-mat">
            <img alt="" src="/img/home-fade-blue.webp" decoding="async" className="img-fade" />
            <div className="centered-text">
                <strong className="big-title text-background">{title}</strong>
                <p className="min-title">{desc}</p>
                {children}
            </div>
        </div>
    );

}

export default Title;