import React, {useState} from 'react';
import {NavLink, useLocation, useNavigate} from "react-router-dom";
import {Utils} from "../../functions/Utils";
import {removeToken} from "../../functions/CookieUtils";

function Header({ userData }) {

    const navigate = useNavigate();

    const [not, setNot] = useState({
        class: "",
        title: "",
        data: ""
    });

    const location = useLocation();

    function isClicked(pageName) {
        if (pageName === "/home" && location.pathname === "/")
            return "btn btn-oh-dark-clicked";
        if (pageName === "/home" && location.pathname === "/home")
            return "btn btn-oh-dark-clicked";
        else if (location.pathname.startsWith(pageName))
            return "btn btn-oh-dark-clicked";
        else
            return "btn btn-oh-dark-trans";
    }

    async function logOut() {
        setNot(Utils.createNot("yes", "Déconnexion", "Déconnexion en cours ..."));

        await Utils.wait(1500);

        await removeToken();

        await Utils.wait(500);

        navigate("/", { replace: true });
        window.location.reload();
    }

    return (
        <div className="header box box-lm-col bg-oh-dark-op">
            <div className={not.class}>
                <span className="not-title">{not.title}</span>
                <span className="not-data">{not.data}</span>
            </div>

            <div className="mobile-raw case-25">
                <NavLink exact to="/">
                    <img className="mini-logo-logo" alt="Logo de OwnHeberg" src="/img/icon.svg" />
                    <span className="mini-logo-title">OwnHeberg</span>
                </NavLink>
            </div>

            <div className="mobile-raw case-auto nav-center">
                <nav className="nav-header">
                    <div className="clicked-bar">
                        <label htmlFor="toggle"><i className="fa-solid fa-bars"/></label>
                    </div>
                    <input type="checkbox" id="toggle"/>

                    <div className="main-menu">
                        <ul>
                            <NavLink exact to="/" className={isClicked("/home")}>Accueil</NavLink>
                            <div className="btn btn-oh-dark-trans dropdown">
                                <span>Hébergement<i className="fa-solid fa-caret-down"></i></span>
                                <ul className="drop">
                                    <li>
                                        <NavLink exact to="/cloud/minecraft" className={isClicked("/cloud/minecraft")}>
                                            Serveur Minecraft
                                            <div>Pour vos serveurs Minecraft.</div>
                                        </NavLink>
                                    </li>
                                    {/*
                                    <li>
                                        <NavLink exact to="/cloud/web" className={isClicked("/cloud/web")}>
                                            Hébergement Web
                                            <div>Pour vos sites Web.</div>
                                        </NavLink>
                                    </li>
                                    */}
                                    <li>
                                        <NavLink exact to="/cloud/bdd" className={isClicked("/cloud/bdd")}>
                                            Base de données
                                            <div>Pour vos bases de données.</div>
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>

                            <div className="btn btn-oh-dark-trans dropdown">
                                <span>Instances<i className="fa-solid fa-caret-down"></i></span>
                                <ul className="drop">
                                    <li>
                                        <NavLink exact to="/cloud/instance/java" className={isClicked("/cloud/instance/java")}>
                                            Java
                                            <div>Pour vos applications/api Java.</div>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink exact to="/cloud/instance/nodejs" className={isClicked("/cloud/instance/nodejs")}>
                                            NodeJS
                                            <div>Pour vos applications/api NodeJS.</div>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink exact to="/cloud/instance/python" className={isClicked("/cloud/instance/python")}>
                                            Python
                                            <div>Pour vos applications/api Python.</div>
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                        </ul>
                    </div>
                </nav>
            </div>

            <div className="mobile-raw case-25 text-right-align">
                {
                    userData === null ?
                        <NavLink className="case-10 btn btn-oh-dark-trans" exact to="/"><i className="fa-solid fa-sync fa-spin"></i></NavLink>
                        :
                        <>
                            {
                                !userData.connected ?
                                    <>
                                        <NavLink className="case-10 btn btn-oh-dark-trans" exact to="/login">Connexion <i aria-hidden="true" className="fas fa-sign-in-alt"></i></NavLink>
                                        <NavLink className="case-10 btn btn-oh-dark-trans" exact to="/register">S'inscrire <i aria-hidden="true" className="fa fa-user-plus"></i></NavLink>
                                    </>
                                    :
                                    <>
                                        <NavLink className="case-10 btn btn-oh-dark-trans" exact to="/panel">
                                            <i aria-hidden="true" className="fa fa-user"></i> Panel utilisateur
                                        </NavLink>
                                        <a className="case-10 btn btn-oh-dark-trans" onClick={logOut}><i className="fa-solid fa-right-from-bracket"></i></a>
                                    </>
                            }
                        </>
                }
            </div>
        </div>
    );
}

export default Header;