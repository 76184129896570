import React from 'react';

function PanelSupport() {
    return (
        <div>
            <h4>En construction ...</h4>
        </div>
    );
}

export default PanelSupport;