import React from 'react';
import {NavLink} from "react-router-dom";
import {CopyToClipboard} from "react-copy-to-clipboard";

let number = 0;

export default function CardInstance({title, img, link, miniDesc, miniIcon, dataCopied, children}) {

    number = (number + 1) % 3;

    let caseAttribute = `case-auto home-case color${number + 1}`;
    if (dataCopied != null) {
        caseAttribute += " cursor-pointer";
    }


    let image;
    if (img != null) {
        if (img.startsWith("fa")) {
            image = <i className={img}/>;
        } else {
            image = <img className="mini-logo-logo" alt="Card Icon" src={img}/>;
        }
    }

    let mini;
    if (miniDesc != null) {
        mini = <h6><i className={miniIcon + " mini-desc"}/>{miniDesc}</h6>;
    }

    const ResultComponent = function () {
        return (
            <>
                <div className="home-case--header">
                    {image}
                    <span className="mini-logo-title">{title}</span>
                </div>

                <h5>{children}</h5>
                {mini}
            </>
        )
    }

    if (dataCopied != null) {
        return (
            <CopyToClipboard text={dataCopied}>
                <div className={caseAttribute}>
                    <ResultComponent/>
                </div>
            </CopyToClipboard>
        )
    } else if (link == null) {
        return (
            <div className={caseAttribute}>
                <ResultComponent/>
            </div>
        );
    } else if (link.startsWith("http")) {
        return (
            <a className={caseAttribute} href={link} target="_blank" rel="noreferrer">
                <ResultComponent/>
            </a>
        )
    } else {
        return (
            <NavLink className={caseAttribute} exact to={link}>
                <ResultComponent/>
            </NavLink>
        )
    }
}