import React, {useState} from 'react';
import {NavLink, useNavigate} from "react-router-dom";
import {Utils} from "../functions/Utils";
import {TypeRequest} from "../functions/TypeRequest";
import Account from "../components/Account";
import {getCode} from "../functions/CookieUtils";

function Register() {

    const navigate = useNavigate();

    const [isInRegister, setIsInRegister] = useState(false);

    const [pseudo, setPseudo] = useState("");
    const [name, setName] = useState("");
    const [firstName, setFirstName] = useState("");
    const [orga, setOrga] = useState("");
    const [email, setEmail] = useState("");
    const [pass1, setPass1] = useState("");
    const [pass2, setPass2] = useState("");
    const [cg, setCg] = useState(false);

    const [not, setNot] = useState({
        class: "",
        title: "",
        data: ""
    });

    async function createAccount() {
        if (!isInRegister) {
            setIsInRegister(true);

            const errorText = "Erreur lors de la création du compte";

            if (pseudo === "" || email === "" || pass1 === "" || pass2 === "") {
                setNot(Utils.createNot("no", errorText, "Vous devez remplir tous les champs obligatoire."));
            }
            else if (!cg) {
                setNot(Utils.createNot("no", errorText, "Vous devez acceptez les conditions générales."));
            }
            else if (pass1 !== pass2) {
                setNot(Utils.createNot("no", errorText, "Les mots de passe ne sont pas identique."));
            }
            else if (pass1.length < 6 || pass1.length > 32) {
                setNot(Utils.createNot("no", errorText, "La taille de votre mot de passe doit-être compris entre 6 et 32 caractères."));

                await Utils.wait(1500);
            }
            else if (pseudo.length < 3 || pseudo.length > 18) {
                setNot(Utils.createNot("no", errorText, "La taille de votre identifiant doit-être compris entre 3 et 18 caractères."));

                await Utils.wait(1500);
            }
            else {
                const response = await Utils.requestApi(TypeRequest.OwnTech,"createAccount",
                    {
                        "pseudo": pseudo,
                        "name": name,
                        "firstName": firstName,
                        "orga": orga,
                        "email": email,
                        "password": await Utils.sha256(pass1),
                        "parrain": getCode()
                    }
                );

                if (response["out"]["valid"] === 1) {
                    setNot(Utils.createNot("yes", "Information", "Création du compte effectuée ! Merci de valider votre adresse e-mail depuis le mail envoyé. Redirection en cours..."));

                    await Utils.wait(4000);

                    navigate("/login", { replace: true });
                    window.location.reload();
                }
                else if (response["out"]["valid"] === 2) {
                    setNot(Utils.createNot("no", errorText, "Ce compte existe déjà."));
                }
                else if (response["out"]["valid"] === 3) {
                    setNot(Utils.createNot("no", errorText, "Ceci n'est pas une adresse email valide."));
                }
                else if (response["out"]["valid"] === 4) {
                    setNot(Utils.createNot("no", errorText, "Ceci n'est pas un mot de passe valide."));
                }
                else if (response["out"]["valid"] === 5) {
                    setNot(Utils.createNot("no", errorText, "Votre identifiant n'est pas valide (caractères autorisés : minuscule, chiffre)."));

                    await Utils.wait(1500);
                }
                else if (response["out"]["valid"] === 6) {
                    setNot(Utils.createNot("no", errorText, "Votre nom, prénom et/ou l'organisation ne sont pas valide (retirer les caractères spéciaux)."));

                    await Utils.wait(1500);
                }
                else if (response["out"]["access"] === "denied") {
                    setNot(Utils.createNot("no", "Nombre de tentatives dépassé", "Vous ne pouvez pas créer de nouveau compte pour le moment."));

                    await Utils.wait(1500);
                }
                else {
                    setNot(Utils.createNot("no", errorText, "Une erreur interne vient d'intervenir."));
                }
            }

            await Utils.wait(2500);

            setNot(Utils.removeNot());
            setIsInRegister(false);
        }
    }

    return (
        <div>
            <div className={not.class}>
                <span className="not-title">{not.title}</span>
                <span className="not-data">{not.data}</span>
            </div>

            <div className="box box-lm-col box-all">

                <Account />

                <div className="case-auto bg-oh-dark">
                    <div className="login-container">
                        <div className="title">
                            <h2>Crée un compte <span className="text-background">OwnTech</span></h2>
                            <p>Accéder à l'ensemble de nos services en créant un compte !</p>
                        </div>

                        <div className="input-container">
                            <h4 className="hovertext" data-hover="Doit contenir entre 3 et 16 caractères. Autorisés : minuscules, chiffres et doit commencer par une lettre.">Identifiant <strong>*</strong></h4>
                            <input name="pseudo" type="text" className="input input-oh-black" placeholder="Identifiant" pattern="[a-z][a-z0-9]{2,17}" onChange={e => setPseudo(e.target.value)} disabled={isInRegister} />

                            <div className="box box-wrap">
                                <div className="case-auto">
                                    <h4>Nom</h4>
                                    <input name="name" type="text" className="input input-oh-black" placeholder="Nom" pattern="[a-zA-Z0-9\s]*" onChange={e => setName(e.target.value)} disabled={isInRegister} />
                                </div>

                                <div className="case-5" />

                                <div className="case-auto">
                                    <h4>Prénom</h4>
                                    <input name="firstName" type="text" className="input input-oh-black" placeholder="Prénom" pattern="[a-zA-Z0-9\s]*" onChange={e => setFirstName(e.target.value)} disabled={isInRegister} />
                                </div>
                            </div>

                            <h4>Organisation</h4>
                            <input name="orga" type="text" className="input input-oh-black" placeholder="Organisation" pattern="[a-zA-Z0-9\s]*" onChange={e => setOrga(e.target.value)} disabled={isInRegister} />

                            <h4>Adresse email <strong>*</strong></h4>
                            <input name="email" type="email" className="input input-oh-black" placeholder="Adresse email" onChange={e => setEmail(e.target.value)} disabled={isInRegister} />

                            <h4 className="hovertext" data-hover="Doit contenir entre 6 et 32 caractères.">Mot de passe <strong>*</strong></h4>
                            <input name="pass1" type="password" className="input input-oh-black" placeholder="Mot de passe" pattern="\w{6,32}" onChange={e => setPass1(e.target.value)} disabled={isInRegister} />
                            <input name="pass2" type="password" className="input input-oh-black small-space" placeholder="Répéter le mot de passe" pattern="\w{6,32}" onChange={e => setPass2(e.target.value)} disabled={isInRegister} />
                        </div>

                        <div className="end">
                            <div className="remember">
                                <input name="cg" type="checkbox" onChange={e => setCg(e.target.checked)} disabled={isInRegister} />
                                <span>J'accepte les <NavLink exact to="/cg" className="text-background">conditions générales</NavLink> <strong>*</strong></span>
                            </div>

                            <a name="login" onClick={createAccount} className="btn btn-oh-black" href>{isInRegister ? <i className="fa-solid fa-sync fa-spin"></i> : "Créer mon compte"}</a>
                        </div>

                        <div className="no-account">
                            <h4>Vous avez déjà un compte <span className="text-background">OwnTech</span> ?</h4>
                            <NavLink exact to="/login" className="btn-xs btn-oh-black">Se connecter</NavLink>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Register;