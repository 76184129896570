import React, {useState} from 'react';
import {Utils} from "../../functions/Utils";
import {TypeRequest} from "../../functions/TypeRequest";
import {getToken} from "../../functions/CookieUtils";
import {NavLink} from "react-router-dom";

function PanelBDDAccess({ bddData, setNot }) {
    if (bddData.bdd_name === null) {
        return (
            <div className="home-panel-container">
                <h3 className="title">Cette base de données n'est pas encore configurée.</h3>

                <NavLink exact to={"/panel/bdd/" + bddData.uuid} className="btn btn-oh-black btn-block">Configurer</NavLink>
            </div>
        );
    } else {
        return <PanelFront bddData={bddData} setNot={setNot}/>;
    }
}

function PanelFront({bddData, setNot}) {

    const [bddPass, setBddPass] = useState("");
    const [isInRegen, setIsInRegen] = useState(false);

    async function getPassword() {
        const response = await Utils.requestApi(TypeRequest.OwnHeberg, "getBddPassword", { "token": getToken(), "uuid": bddData.uuid });

        if (response.out.valid === 1) {
            setBddPass(response.out.password);
        } else {
            setBddPass("Vous n'avez pas la permission.");
        }
    }

    async function regenPassword() {
        if (!isInRegen) {
            setIsInRegen(true);

            const response = await Utils.requestApi(TypeRequest.OwnHeberg, "regenBddPassword", { "token": getToken(), "uuid": bddData.uuid });

            if (response.out.valid === 1) {
                setNot(Utils.createNot("yes", "Information", "Le mot de passe a été regénéré avec succès !"));
                setBddPass("");
            } else if (response.out.valid === 4) {
                setNot(Utils.createNot("no", "Erreur", "Vous n'avez pas la permission de regénérer le mot de passe."));
            } else if (response.out.valid === 5) {
                setNot(Utils.createNot("no", "Erreur", "La base de données n'existe pas."));
            } else if (response.out.access === "denied") {
                setNot(Utils.createNot("no", "Erreur", "Limite de régénérations du mot de passe atteinte."));
            } else {
                setNot(Utils.createNot("no", "Erreur", "Une erreur est survenue lors de la regénération du mot de passe."));
            }

            await Utils.wait(2500);

            setNot(Utils.removeNot());
            setIsInRegen(false);
        }
    }

    if (bddPass === "") getPassword().then();

    return (
        <div className="bdd-panel">
            <div className="box box-lm-col">
                <div className="case-auto bdd-case">
                    <div className="brand">
                        <i className="fa-solid fa-earth-europe"></i>Accès externe
                    </div>

                    <hr/>

                    <div className="bdd-access">
                        <div className="bdd-info">
                            <h4>Adresse du serveur</h4>
                            <input className="input input-block input-oh-black" type="text" value="sql.ownheberg.com" disabled/>
                        </div>

                        <div className="bdd-info">
                            <h4>Nom base de données</h4>
                            <input className="input input-block input-oh-black" type="text" value={bddData.bdd_name} disabled/>
                        </div>

                        <div className="bdd-info">
                            <h4>Nom d'utilisateur</h4>
                            <input className="input input-block input-oh-black" type="text" value={bddData.bdd_name} disabled/>
                        </div>

                        <div className="bdd-info">
                            <h4>Mot de passe</h4>
                            <a className="btn btn-block btn-oh-black" href="#pass">Voir ou modifier le mot de passe</a>

                            <div id="pass" className="overlay">
                                <div className="popup">
                                    <a className="close" href="#"><i className="fa-solid fa-xmark"></i></a>

                                    <h3>Gestion du mot de passe</h3>
                                    <h5>{bddData.bdd_name}</h5>

                                    <div className="content">
                                        <div className="bdd-info">
                                            <p>Mot de passe</p>
                                            <input className="input input-block input-oh-black" type="text" value={bddPass ?? "Chargement ..."} disabled/>
                                            <h6>Attention, si vous modifiez manuellement le mot de passe, il ne sera pas mis à jour ici.</h6>
                                        </div>

                                        <div className="bdd-info">
                                            <a className="btn btn-block btn-oh-black" onClick={regenPassword}>{isInRegen ? <i className="fa-solid fa-sync fa-spin"></i> : "Régénérer le mot de pass"}</a>
                                            <h6>Limité à une fois par heure.</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="case-auto bdd-case">
                    <div className="brand">
                        <i className="fa-solid fa-table-columns"></i>Interface web
                    </div>

                    <hr/>

                    <div className="bdd-access">
                        <h4>Accès au panel PHPMyAdmin</h4>
                        <a className="btn btn-block btn-oh-black" href="https://sql.ownheberg.com/" target="_blank">PHPMyAdmin <i className="fa-solid fa-arrow-up-right-from-square fa-xs"></i></a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PanelBDDAccess;