import React, {useReducer, useState} from 'react';
import PaypalButton from '../PaypalButton';
import {Utils} from "../../functions/Utils";
import {TypeRequest} from "../../functions/TypeRequest";
import {getToken} from "../../functions/CookieUtils";

function PanelCredit({ userData, setUserData, setNot }) {

    const [credit, setCredit] = useState(0);
    const [history, setHistory] = useState(null);
    const [ignored, forceUpdate] = useReducer(x => x + 1, 0);

    const handleChange = (e) => {
        setCredit(e.target.value);
        forceUpdate();
    }

    async function getHistory() {
        const response = await Utils.requestApi(TypeRequest.OwnHeberg, "getPbHistory", { "token":  getToken()});
        setHistory(response.out);
    }

    if (history === null)
        getHistory().then();

    function getPb() {

        if (userData === null) {
            return (
                <span className="hovertext" data-hover="Chargement de votre nombre de crédit.">
                    <span className="credit-amount"><i className="fa-solid fa-sync fa-spin"></i></span>
                </span>
            );
        }
        else {

            const money = userData.pb;

            return (
                <span className="hovertext" data-hover={"Ce nombre de crédits correspond à " + money / 100 + "€"}>
                    <span className="credit-amount">
                        {money} <i className="fa-solid fa-cubes-stacked"></i>
                    </span>
                </span>
            );
        }
    }

    function getHistoryFront() {
        if (history === null) {
            return (
                <div className="text-center-align">
                    <i className="fa-solid fa-sync fa-spin text-center-align"></i>
                </div>
            );
        } else {
            if (history["historyCount"] > 0) {

                let historyList = [];
                const historyCount = history["historyCount"];
                const options = { hour: "numeric", minute: "numeric", year: "numeric", month: "numeric", day: "numeric" };

                for (let i = 1; i <= historyCount; i++) {
                    const date = new Date(history[i]["date"] * 1000);
                    const type = history[i]["type"];
                    const pb = history[i]["pb"];

                    const temp = {
                        date: date.toLocaleDateString("fr-FR", options),
                        type: type,
                        pb: pb
                    };

                    historyList.push(temp);
                }

                return (
                    <div>
                        <ul className="credit-history-list">
                            <li className="credit-history-title">
                                <div className="box text-center-align">
                                    <div className="case-auto">Date</div>
                                    <div className="case-auto">Type de paiement</div>
                                    <div className="case-auto">Crédits</div>
                                </div>
                            </li>
                            {historyList.map(service => {
                                return (
                                    <li className="credit-history-element">
                                        <div className="box text-center-align">
                                            <div className="case-auto">{service.date}</div>
                                            <div className="case-auto">{service.type === "paidCredit" ? "Achat" : service.type === "new" ? "Achat service" : service.type === "renew" ? "Renouvellement service" : "Autre"}</div>
                                            <div className={"case-auto " + (service.pb > 0 ? "green" : "red")}>{service.pb}</div>
                                        </div>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                );

            } else {
                return (
                    <div className="text-center-align">Vous n'avez effectué aucun achat de crédit.</div>
                )
            }
        }
    }

    async function verifyCredit() {
        if (credit < 500) {
            setNot(Utils.createNot("no", "Erreur", "Vous devez acheter au minimum 5€ de crédits."));
            await Utils.wait(3000);
            setNot(Utils.removeNot());
        }
    }

    return (
        <>

            <div className="box">
                <div className="case-auto credit-case">
                    <div className="credit-title">
                        Vos crédit(s)
                    </div>

                    <hr />

                    <div className="text-center-align">
                        {getPb()}
                    </div>
                </div>
            </div>

            <div className="box box-lm-col">
                <div className="case-auto credit-case">
                    <div className="credit-title">
                        <i className="fa-solid fa-money-check-dollar"></i>Acheter des crédits
                    </div>

                    <hr />

                    <div className="credit-payment">
                        <h4>Entrer le nombre de crédits</h4>

                        <input className="input input-oh-black credit-input" id="pbTotal" type="number" min="500" max="25000" name="pb" placeholder="1 crédit = 0.01 euro" onChange={e => handleChange(e)} />
                        <p className="text-center-align">Pour un total de : <strong>{credit / 100} €</strong> TTC (minimum 5 €).</p>

                        <a className="btn btn-block btn-oh-black" href={credit >= 500 ? "#payment" : "#"} onClick={verifyCredit}>Procéder au paiement</a>

                        <div id="payment" className="overlay">
                            <div className="popup">
                                <a className="close" href="#"><i className="fa-solid fa-xmark"></i></a>

                                <h3>Mode de paiement</h3>
                                <h5><strong>{credit}</strong> crédit(s) pour <strong>{credit / 100} €</strong> TTC</h5>

                                <div className="content">

                                    <h4><i className="fa-brands fa-paypal"></i>PayPal</h4>
                                    {userData &&
                                        <PaypalButton type="pb" dataPayment={credit} userData={userData} setUserData={setUserData} forceUpdate={forceUpdate} />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="case-auto credit-case">
                    <div className="credit-title">
                        <i className="fa-solid fa-scroll"></i>Historique
                    </div>

                    <hr />

                    {getHistoryFront()}
                </div>
            </div>

            <p className="credit-no">Attention les crédits ne peuvent pas être remboursés.</p>
        </>
    );
}

export default PanelCredit;