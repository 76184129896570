import React from 'react';
import Header from "../components/Helpers/Header";
import Footer from "../components/Helpers/Footer";
import {NavLink} from "react-router-dom";

function NotFoundPage({ userData }) {
    return (
        <div>
            <Header userData={userData} />

            <div className="not-found bg-oh-dark-light">
                <h1 className="text-background">Erreur 404</h1>
                <h3>Attention cette page n'existe pas.</h3>
                <NavLink excat to="/" className="btn btn-oh-black">Retour à l'accueil</NavLink>
            </div>

            <Footer />
        </div>
    );
}

export default NotFoundPage;