import React, {useEffect, useState} from 'react';
import PanelError from "../components/Panel/PanelError";

import {NavLink, useNavigate, useParams} from "react-router-dom";
import {Utils} from "../functions/Utils";
import {getToken} from "../functions/CookieUtils";
import {TypeRequest} from "../functions/TypeRequest";
import PanelBDDHome from "../components/PanelBDD/PanelBDDHome";
import PanelBDDAccess from "../components/PanelBDD/PanelBDDAccess";
import PanelBDDPerm from "../components/PanelBDD/PanelBDDPerm";
import PanelBDDSettings from "../components/PanelBDD/PanelBDDSettings";

function PanelBDD({ userData, setUserData }) {

    const navigate = useNavigate();
    const params = useParams();

    const page = params.page ?? "home";
    const uuid = params.uuid ?? null;

    const [bddData, setBddData] = useState(null);

    const [not, setNot] = useState({
        class: "",
        title: "",
        data: ""
    });

    useEffect(() => {
        async function getBddData() {
            const token = await getToken();

            if (Utils.strExist(token)) {
                const response = await Utils.requestApi(TypeRequest.OwnHeberg, "getBddInfo", { "token": token, "uuid": uuid });

                if (response["out"]["validBdd"] === true) {
                    response["out"]["uuid"] = uuid;
                    setBddData(response["out"]);
                }
                else {
                    navigate("/panel/instance", { replace: true });
                    window.location.reload();
                }
            }
        }

        getBddData().then();
    }, [navigate, uuid]);

    function getComponent() {
        switch (page) {
            case "home":
                return <PanelBDDHome userData={userData} bddData={bddData} setBddData={setBddData} setNot={setNot} />;

            case "access":
                return <PanelBDDAccess bddData={bddData} setNot={setNot} />;

            case "users":
                return <PanelBDDPerm bddData={bddData} setNot={setNot} />;

            case "settings":
                return <PanelBDDSettings userData={userData} bddData={bddData} setBddData={setBddData} setNot={setNot} />;

            default:
                return <PanelError />;
        }
    }

    return (
        <div className={userData === null || bddData === null ? "waiting" : ""}>

            <span className="waiting-info">Chargement de la base de données <i className="fa-solid fa-sync fa-spin waiting-icon"></i></span>

            <div className={not.class}>
                <span className="not-title">{not.title}</span>
                <span className="not-data">{not.data}</span>
            </div>

            <div className="nav-panel">
                <ul className="nav-list">
                    <li className="logo">
                        <NavLink exact to="/">
                            <img className="mini-logo-logo" alt="Logo de OwnHeberg" src="/img/icon.svg" />
                            <span className="mini-logo-title">OwnHeberg</span>
                        </NavLink>
                    </li>

                    <li className="route">
                        <NavLink exact to={"/panel/bdd/" + uuid} className={page === "home" ? "clicked" : ""}>
                            <i className="fa-solid fa-circle-info"></i>
                            <span className="links_name">Informations</span>
                        </NavLink>
                    </li>

                    <li className="route">
                        <NavLink exact to={"/panel/bdd/" + uuid + "/access"} className={page === "access" ? "clicked" : ""}>
                            <i className="fa-solid fa-universal-access"></i>
                            <span className="links_name">Accès</span>
                        </NavLink>
                    </li>

                    <li className="route">
                        <NavLink exact to={"/panel/bdd/" + uuid + "/users"} className={page === "users" ? "clicked" : ""}>
                            <i className="fa-solid fa-file-contract"></i>
                            <span className="links_name">Permissions</span>
                        </NavLink>
                    </li>

                    <li className="route">
                        <NavLink exact to={"/panel/bdd/" + uuid + "/settings"} className={page === "settings" ? "clicked" : ""}>
                            <i className="fa-solid fa-gear"></i>
                            <span className="links_name">Paramètres</span>
                        </NavLink>
                    </li>

                    <div className='separator' />

                    <li className="route">
                        <NavLink exact to="/panel/instance">
                            <i className="fa-solid fa-house-chimney-user"></i>
                            <span className="links_name">Retour</span>
                        </NavLink>
                    </li>

                    <li className="name">
                        {
                            userData !== null && userData.connected ?
                                <>Connectée en tant que {userData.pseudo}</>
                                :
                                <>Bienvenue</>
                        }
                    </li>
                </ul>
            </div>

            <section className="home-section">
                <TitlePanel page={page} bddName={bddData === null ? "Accueil" : bddData.name} />
                {userData === null || bddData === null ? "" : getComponent()}
            </section>
        </div>
    );
}

function TitlePanel(props) {

    let title, desc;

    switch (props.page) {
        case "home":
            title = props.bddName;
            desc = "Panneau de contôle de votre base de données.";
            break;

        case "access":
            title = "Gérer les accès";
            desc = "Gérer les accès de votre base de données.";
            break;

        case "users":
            title = "Permissions";
            desc = "Gérer les permissions et les comptes ayant accès à votre base de données.";
            break;

        case "settings":
            title = "Paramètres";
            desc = "Paramètres de votre base de données.";
            break;

        default:
            title = "Erreur";
            desc = "Cette page n'existe pas.";
            break;
    }

    return (
        <div className="panel-title">
            <h1 className="text-background panel-title-block">{title}</h1>
            <span className="page-desc">{desc}</span>
            <hr />
        </div>
    );
}

export default PanelBDD;