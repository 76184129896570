import React, {useReducer, useState} from 'react';
import {Utils} from "../../functions/Utils";
import {TypeRequest} from "../../functions/TypeRequest";
import {getToken} from "../../functions/CookieUtils";
import {CopyToClipboard} from "react-copy-to-clipboard";

function PanelInvoice({setNot}) {

    const [filleuls, setFilleuls] = useState(null);
    const [code, setCode] = useState("");

    const [ignored, forceUpdate] = useReducer(x => x + 1, 0);

    async function getFilleuls() {
        const response = await Utils.requestApi(TypeRequest.OwnHeberg, "getSponsor", { "token":  getToken()});
        setFilleuls(response.out);
    }

    if (filleuls === null)
        getFilleuls().then();

    function getParrainFront() {

        let filleulsList = [];

        const filleulsCount = filleuls["filleulCount"];

        for (let i = 1; i <= filleulsCount; i++) {
            const pseudo = filleuls["filleul"][i]["pseudo"];
            const valid = filleuls["filleul"][i]["valid"];

            const temp = {
                id: i,
                pseudo: pseudo,
                valid: valid
            };

            filleulsList.push(temp);
        }

        return (
            <ul className="credit-history-list">
                <li className="credit-history-title">
                    <div className="box text-center-align">
                        <div className="case-auto">Pseudo (masqué)</div>
                        <div className="case-auto">Filleul validé</div>
                    </div>
                </li>
                {filleulsList.map(filleul => {
                    return (
                        <li className="credit-history-element">
                            <div className="box text-center-align">
                                <div className="case-auto">{filleul.pseudo}</div>
                                <div className="case-auto">
                                    {filleul.valid === "1" ?
                                        <span className="sur-good">Validé</span> : <span className="sur-error hovertext" data-hover="Cet utilisateur doit effectuer au minimum 5€ de transaction.">Non validé</span>
                                    }
                                </div>
                            </div>
                        </li>
                    );
                })}
            </ul>
        );
    }
    async function createCode() {
        const response = await Utils.requestApi(TypeRequest.OwnHeberg, "createSponsorLink", { "token":  getToken(), "url": code});

        if (response.out.valid === 1) {
            filleuls["url"] = code;
            setFilleuls(filleuls);
            forceUpdate();

            setNot(Utils.createNot("yes", "Information", "Votre code vient d'être créé, vous pouvez maintenant partager l'URL."));
        } else {
            setNot(Utils.createNot("no", "Erreur", "Ce code est déjà utilisé, ou il est invalide, caractères autorisés : a-z, 0-9"));
        }

        await Utils.wait(3000);
        setNot(Utils.removeNot());
    }

    if (filleuls === null) {
        return (
            <div className="panel-instance">
                <div className="instance-loading">
                    <span>Chargement des informations<i className="fa-solid fa-sync fa-spin"></i></span>
                </div>
            </div>
        );
    } else {
        return (
            <div className="panel-instance">
                <div className="box box-lm-col">
                    <div className="case-auto credit-case">
                        <div className="credit-title">
                            <i className="fa-solid fa-link"></i>Votre lien d'affiliation
                        </div>

                        <hr />

                        <div className="filleul-content">
                            {filleuls["url"] !== null ?
                                <>
                                    <p className="text-center-align">Partagez ce lien pour parrainer de nouveaux utilisateurs</p>
                                    <CopyToClipboard text={"https://ownheberg.com/p/" + filleuls["url"]}>
                                        <input type="text" name="code" className="input input-oh-black input-block" value={"https://ownheberg.com/p/" + filleuls["url"]} readOnly />
                                    </CopyToClipboard>
                                    <p className="text-center-align">Remportez <strong>500</strong> crédits à chaque palier de 5 filleuls validés.</p>
                                </>
                                :
                                <>
                                    <p className="text-center-align">Choissisez un code de parrainage :</p>
                                    <input type="text" name="code" className="input input-oh-black input-block" placeholder="Code de parrainage" onChange={e => setCode(e.target.value)} />
                                    <a className="btn-xs btn-oh-black btn-block" onClick={createCode}>Valider</a>
                                </>
                            }
                        </div>
                    </div>

                    <div className="case-auto credit-case">

                        <div className="credit-title">
                            <i className="fa-solid fa-file-lines"></i>Vos filleuls
                        </div>

                        <hr />

                        {filleuls["filleulCount"] > 0 ?
                            getParrainFront()
                            :
                            <p>Vous n'avez pas de filleule.</p>
                        }

                    </div>
                </div>
            </div>
        );
    }
}

export default PanelInvoice;