import React from 'react';

let number = 0;

export default function CardInfo({miniTitle, title, img, place, children}) {

    number = (number + 1) % 3;

    let imgLeft;
    let imgRight;

    switch (place) {
        case "left":
            imgLeft = "case-auto text-center-align mobile-off";
            imgRight = "case-auto text-center-align mobile-on off";
            break;

        case "right":
            imgLeft = "case-auto text-center-align mobile-off off";
            imgRight = "case-auto text-center-align mobile-on";
            break;
    }

    return (
      <div className={"box box-lm-col card-info color" + (number + 1)}>

          <div className={imgLeft}>
              <img src={img} alt={title} loading="lazy" />
          </div>
          <div className="case-auto text-area">
              <h2 className="mini-title">{miniTitle}</h2>
              <h1 className="title text-background">{title}</h1>

              <span className="child">{children}</span>
          </div>
          <div className={imgRight}>
              <img src={img} alt={title} loading="lazy" />
          </div>

      </div>
    );
}