import React, {useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import {Utils} from "../functions/Utils";
import {TypeRequest} from "../functions/TypeRequest";
import Account from "../components/Account";

function Forgot() {

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const resetCode = searchParams.get("resetCode");

    const [isInForgot, setIsInForgot] = useState(false);

    const [email, setEmail] = useState("");
    const [pass1, setPass1] = useState("");
    const [pass2, setPass2] = useState("");

    const [not, setNot] = useState({
        class: "",
        title: "",
        data: ""
    });

    async function resetRequest() {
        if (!isInForgot) {
            setIsInForgot(true);

            const errorText = "Erreur";

            if (email === "") {
                setNot(Utils.createNot("no", errorText, "Vous devez renseigner votre adresse email."));
            }
            else {
                const response = await Utils.requestApi(TypeRequest.OwnTech,"resetRequest", {
                        "email": email
                });

                if (response["out"]["valid"] === 1) {
                    setNot(Utils.createNot("yes", "Information", "Un email de réinitialisation de mot de passe vous a été envoyé !"));
                    await Utils.wait(1500);
                }
                else if (response["out"]["valid"] === 2) {
                    setNot(Utils.createNot("no", errorText, "Ceci n'est pas une adresse email valide."));
                }
                else if (response["out"]["access"] === "denied") {
                    setNot(Utils.createNot("no", "Nombre de tentatives dépassé", "Vous avez dépassé le nombre de tentatives. Veuillez réessayer dans 1 heure."));

                    await Utils.wait(1500);
                }
                else {
                    setNot(Utils.createNot("no", errorText, "Une erreur interne est survenue."));
                }
            }

            await Utils.wait(2500);

            setNot(Utils.removeNot());
            setIsInForgot(false);
        }
    }

    async function resetPassword() {
        if (!isInForgot) {
            setIsInForgot(true);

            const errorText = "Erreur";

            if (pass1 === "" || pass2 === "") {
                setNot(Utils.createNot("no", errorText, "Vous devez renseigner votre nouveau mot de passe."));
            }
            else if (pass1 !== pass2) {
                setNot(Utils.createNot("no", errorText, "Les mots de passe ne sont pas identique."));
            }
            else if (pass1.length < 6 || pass1.length > 32) {
                setNot(Utils.createNot("no", errorText, "La taille de votre mot de passe doit-être compris entre 6 et 32 caractères."));

                await Utils.wait(1500);
            }
            else {
                const response = await Utils.requestApi(TypeRequest.OwnTech,"resetPassword", {
                    "resetCode": resetCode,
                    "password": await Utils.sha256(pass1),
                });

                if (response["out"]["valid"] === 1) {
                    setNot(Utils.createNot("yes", "Information", "Votre mot de passe a été réinitialisé ! Redirection en cours..."));

                    await Utils.wait(3000);

                    navigate("/login", { replace: true });
                    window.location.reload();
                }
                else if (response["out"]["valid"] === 2) {
                    setNot(Utils.createNot("no", errorText, "Ceci n'est pas un mot de passe valide."));
                }
                else if (response["out"]["access"] === "denied") {
                    setNot(Utils.createNot("no", "Nombre de tentatives dépassé", "Vous avez dépassé le nombre de tentatives. Veuillez réessayer dans 30 minutes."));

                    await Utils.wait(1500);
                }
                else {
                    setNot(Utils.createNot("no", errorText, "Une erreur interne est survenue."));
                }
            }

            await Utils.wait(2500);

            setNot(Utils.removeNot());
            setIsInForgot(false);
        }
    }

    return (
        <div>
            <div className={not.class}>
                <span className="not-title">{not.title}</span>
                <span className="not-data">{not.data}</span>
            </div>

            <div className="box box-lm-col box-all">

                <Account />

                {resetCode === null ?
                    <div className="case-auto bg-oh-dark">
                        <div className="login-container">
                            <div className="title">
                                <h2>Mot de passe oublié</h2>
                                <p>Entrez votre adresse e-mail pour recevoir un lien de réinitialisation de votre mot de passe.</p>
                            </div>

                            <div className="input-container">
                                <h4>Adresse email <strong>*</strong></h4>
                                <input name="email" type="text" className="input input-oh-black" placeholder="Adresse email" onChange={e => setEmail(e.target.value)} />
                            </div>

                            <div className="end">
                                <a name="resetRequest" onClick={resetRequest} className="btn btn-oh-black" href>{isInForgot ? <i className="fa-solid fa-sync fa-spin"></i> : "Confirmer"}</a>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="case-auto bg-oh-dark">
                        <div className="login-container">
                            <div className="title">
                                <h2>Mot de passe oublié</h2>
                                <p>Entrez votre adresse e-mail pour recevoir un lien de réinitialisation de votre mot de passe.</p>
                            </div>

                            <div className="input-container">
                                <h4>Nouveau mot de passe <strong>*</strong></h4>
                                <input name="pass1" type="password" className="input input-oh-black" placeholder="Mot de passe" pattern="\w{6,32}" onChange={e => setPass1(e.target.value)} />
                                <input name="pass2" type="password" className="input input-oh-black small-space" placeholder="Répéter le mot de passe" pattern="\w{6,32}" onChange={e => setPass2(e.target.value)} />
                            </div>

                            <div className="end">
                                <a name="resetRequest" onClick={resetPassword} className="btn btn-oh-black" href>{isInForgot ? <i className="fa-solid fa-sync fa-spin"></i> : "Changer de mot de passe"}</a>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
}

export default Forgot;