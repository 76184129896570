import React from 'react';

function PanelError() {
    return (
        <div className="text-center-align">
            <h3>Erreur 404</h3>
            <h4>Cette page n'existe pas.</h4>
        </div>
    );
}

export default PanelError;