import React from 'react';
import {NavLink} from "react-router-dom";

function CardService({ service, isRenew, panel }) {

    function getStatus(status) {

        switch (status) {
            case "valid":
                return (
                    <span className="hovertext" data-hover="Tout est en ordre.">
                        <i className="fa-solid fa-circle green"/>
                    </span>
                );
            case "suspended":
                return (
                    <span className="hovertext" data-hover="Votre service est suspendu, vous devez le renouveler.">
                        <i className="fa-solid fa-hourglass-end red"/>
                    </span>
                );
            case "bddWaiting":
                return (
                    <span className="hovertext" data-hover="Votre base de données est en attente de configuration.">
                        <i className="fa-solid fa-gears yellow"/>
                    </span>
                );
            case "maxStorage":
                return (
                    <span className="hovertext" data-hover="Votre base de données a atteint le quota de stockage autorisé. Veuillez supprimer des données pour pouvoir continuer à utiliser la base de données.">
                       <i className="fa-solid fa-hard-drive red"></i>
                    </span>
                );
            case "installing":
                return (
                    <span className="hovertext" data-hover="Votre service est en cours d'installation.">
                        <i className="fa-solid fa-sync fa-spin yellow"/>
                    </span>
                );
            default:
                return;
        }
    }

    function getBadge(remaining) {

        const day = Math.round(remaining / 60 / 60 / 24);
        const hour = Math.round(remaining / 60 / 60);
        const minute = Math.round(remaining / 60);

        let remainingScreen;
        if (!Number.isInteger(remaining)) {
            if (remaining === "admin") {
                remainingScreen = <span className="sur-good">Illimité</span>;
            } else if (remaining === "error") {
                remainingScreen = <span className="sur-error">Erreur</span>;;
            } else {
                remainingScreen = <span className="sur-cyan">Expire avec <strong>{remaining}</strong></span>;
            }
        }
        else if (remaining <= 0) {
            const removedTime = 4 * 24 * 60 * 60;
            const absRemaining = Math.abs(remaining);

            const removeRemaining = removedTime - absRemaining;

            const day = Math.round(removeRemaining / 60 / 60 / 24);
            const hour = Math.round(removeRemaining / 60 / 60);
            const minute = Math.round(removeRemaining / 60);

            if (removeRemaining > 0) {
                if (day > 2) {
                    remainingScreen = <span className="sur-error">Suppressions dans {day} jours</span>;
                }
                else if (hour > 0) {
                    remainingScreen = <span className="sur-error">Suppressions dans {hour} heure{hour > 1 && "s"}</span>;
                }
                else {
                    remainingScreen = <span className="sur-error">Suppressions dans {minute} minute{minute > 1 && "s"}</span>;
                }
            } else {
                remainingScreen = <span className="sur-error">Suppressions en cours ...</span>;
            }
        }
        else if (remaining > 0) {
            if (day > 2) {
                remainingScreen = <span className="sur-good">Expire dans {day} jours</span>;
            }
            else if (hour > 0) {
                remainingScreen = <span className="sur-warn">Expire dans {hour} heure{hour > 1 && "s"}</span>;
            }
            else {
                remainingScreen = <span className="sur-warn">Expire dans {minute} minute{hour > 1 && "s"}</span>;
            }
        }
        else {
            remainingScreen = <span className="sur-error">Erreur</span>;
        }

        return remainingScreen;
    }

    function getPanelURL() {
        switch (service.type) {
            case "bdd":
                return (<NavLink exact to={'/panel/bdd/' + service.supportId} className="btn-sur btn-oh-dark-clicked"><i className="fa-solid fa-table-columns"></i> Panel</NavLink>);
            default:
                return (<a href={process.env.REACT_APP_API_URL_PANEL + "server/" + service.supportId} className="btn-sur btn-oh-dark-clicked"><i className="fa-solid fa-table-columns"></i> Panel</a>);
        }
    }

    if (!Number.isInteger(service.remaining))
        isRenew = false;

    return (
        <li className='instance-element'>
            <div className="box box-lm-col">
                <div className="case-auto">
                    {getStatus(service.status)}
                    <span className="title">{service.instanceName}</span>
                    <div className="instance-description">{service.description}</div>
                </div>
                <div className="case-20 instance-id">
                    <span className="instance-id-text hovertext" data-hover="Cette identifiant permet de retrouver votre instance.">Identifiant : <strong>{service.supportId}</strong></span>
                </div>
                <div className="case-auto text-right-align instance-btn">
                    {getBadge(service.remaining)}
                    <div>
                        {isRenew &&
                            <NavLink exact to={"/panel/renew/" + service.supportId} className="btn-sur btn-oh-dark-clicked"><i className="fa-solid fa-arrows-rotate"></i> Renouveler</NavLink>
                        }
                        {panel &&
                            getPanelURL()
                        }
                    </div>
                </div>
            </div>
        </li>
    );

}

export default CardService;